import { Autocomplete, TextFieldProps } from "@mui/material";

import MDInput from "../MDInput";

interface AutocompleteProps {
  setFieldValue?: (field: string, value: string | number) => void;
  options: any[];
  getOptionLabel?: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
}

const MDAutocomplete = (props: TextFieldProps & AutocompleteProps) => {
  const {
    options,
    value,
    setFieldValue,
    getOptionLabel,
    isOptionEqualToValue,
    onInputChange,
    ...rest
  } = props;

  return (
    <Autocomplete
      disabled={rest?.disabled}
      renderInput={(params) => (
        <MDInput
          {...rest}
          {...params}
          inputProps={{ style: { padding: 3 }, ...params.inputProps }}
        />
      )}
      isOptionEqualToValue={
        isOptionEqualToValue
          ? isOptionEqualToValue
          : (option, value) => option === value
      }
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option: unknown) => {
              const selectedLabel = options?.find(
                (opt: { value: string; label: string }) => opt.value === option
              );
              return (
                selectedLabel?.label || (option as any)?.label || option || ""
              );
            }
      }
      onChange={(event, valueSelected) => {
        const newVal = valueSelected as any;
        setFieldValue(rest.name, newVal || null);
      }}
      options={options || []}
      value={value || null}
      onInputChange={onInputChange}
    />
  );
};

export default MDAutocomplete;
