export enum EDocumentType {
  VEHICLE_REGISTRATION = "VEHICLE_REGISTRATION",
  IDENTITY_CARD = "IDENTITY_CARD",
  OFFER_REQUEST = "OFFER_REQUEST",
  COMPANY_REGISTRATION = "COMPANY_REGISTRATION",
  OFFER = "OFFER",
  INSURANCE_POLICY = "INSURANCE_POLICY",
  PAYMENTS_EXPORT = "PAYMENTS_EXPORT",
  PETITION_CLIENT_FILE = "PETITION_CLIENT_FILE",
  NOTA_FUNDAMENTARE = "NOTA_FUNDAMENTARE",
  MESAJ_ASF = "MESAJ_ASF",
  PETITION_RESPONSE = "PETITION_RESPONSE",
  STATEMENT = "STATEMENT",
  QUARTERLY_REPORT = "QUARTERLY_REPORT",
}

export enum EFileTypes {
  PETITION_CLIENT_FILE = "PETITION_CLIENT_FILE",
  NOTA_FUNDAMENTARE = "NOTA_FUNDAMENTARE",
  MESAJ_ASF = "MESAJ_ASF",
}
