import { useCallback, useEffect, useMemo, useState } from "react";

export const usePaginationPages = ({
  gotoPage,
  length,
  pageSize,
  onPaginationChange,
}: {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  length: number;
  pageSize: number;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPages = useMemo<number>(() => {
    return Math.ceil(length / pageSize);
  }, [length, pageSize]);

  const canGo = useMemo(() => {
    return {
      next: currentPage < totalPages,
      previous: currentPage - 1 > 0,
    };
  }, [currentPage, totalPages]);

  const pages = useMemo(() => {
    const start = Math.floor((currentPage - 1) / 5) * 5;
    const end = start + 5 > totalPages ? totalPages : start + 5;
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }, [currentPage, totalPages]);

  useEffect(() => {
    gotoPage(currentPage - 1);
    onPaginationChange(currentPage, pageSize);
  }, [currentPage, gotoPage, pageSize]);

  const goTo = (pg: number) => {
    setCurrentPage(pg);
  };

  useEffect(() => {
    if (totalPages < currentPage) {
      setCurrentPage(1);
    }
  }, [totalPages]);

  useEffect(() => {
    if (pageSize) {
      goTo(1);
    }
  }, [pageSize]);

  // Setting the entries starting point
  const entriesStart = currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (currentPage === 1) {
    entriesEnd = pageSize;
  } else {
    entriesEnd = pageSize * currentPage;
  }

  const goNext = useCallback(() => {
    if (canGo.next) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [canGo]);

  const goPrev = useCallback(() => {
    if (canGo.previous) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [canGo]);

  return {
    canGo,
    currentPage,
    pages,
    goTo,
    goNext,
    goPrev,
    entriesStart,
    entriesEnd,
  };
};
