import { Grid, IconButton, Typography } from "@mui/material";
import { useFormik } from "formik";
import { translate } from "translations/i18n";
import * as yup from "yup";

import MDAutocomplete from "components/mui/MDAutocomplete";
import MDBox from "components/mui/MDBox";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import ConfirmationModal from "components/ConfirmationModal";
import { IUser } from "interfaces";
import { Endpoints, deleteRequest, getRequest, postRequest } from "helpers/api";
import { useNavigate } from "react-router-dom";

interface Props {
  row: IUser;
  isUserDetailsPage?: boolean;
  refreshUsersList?: (users: IUser[]) => void;
}

const DeleteUser = (props: Props) => {
  const { row, isUserDetailsPage = false, refreshUsersList } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      getRequest(Endpoints.USER)
        .then((res) => {
          const usersRes = res.filter((user: IUser) => user.id !== row.id);
          setUsers(usersRes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [open]);

  const validationSchema = yup.object({
    assigneeUser: yup
      .object()
      .required(
        `${translate("users.nameInputLabel")} ${translate(
          "users.validationRequired"
        )}`
      ),
  });

  const { values, errors, submitForm, setFieldValue, resetForm } = useFormik({
    initialValues: { assigneeUser: null },
    validationSchema: validationSchema,
    onSubmit: () => {
      reassignTasks(values.assigneeUser.id, row.id);
      setOpen(false);
      resetForm();
    },
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
  });

  const deactivateUser = (userId: number) => {
    deleteRequest(`${Endpoints.USER}/deactivate/${userId}`)
      .then(() => {
        if (isUserDetailsPage) {
          navigate("/users/userslist");
        } else {
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== userId)
          );
          refreshUsersList(users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reassignTasks = (assigneeId: number, userId: number) => {
    postRequest(`${Endpoints.USER}/reassign/${assigneeId}/${userId}`)
      .then(() => {
        deactivateUser(userId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <IconButton
        color={isUserDetailsPage ? "success" : "default"}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Delete />
      </IconButton>
      <ConfirmationModal
        title={translate("users.deactivateUserTitle")}
        description={`${translate("users.deactivateUserDescription")} ${
          row.name
        }?`}
        handleSubmit={() => {
          submitForm();
        }}
        open={open}
        handleClose={() => {
          resetForm();
          setOpen(false);
        }}
      >
        <Typography variant="body2">
          {translate("users.dropdownUsersDescription")}
        </Typography>
        <MDBox mt={2}>
          <Grid item>
            <MDAutocomplete
              name="assigneeUser"
              variant="outlined"
              label={translate("users.nameInputLabel")}
              value={values.assigneeUser}
              options={users}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(item) => item.name}
              setFieldValue={setFieldValue}
              error={Boolean(errors.assigneeUser)}
              fullWidth
            />
          </Grid>
        </MDBox>
      </ConfirmationModal>
    </>
  );
};
export default DeleteUser;
