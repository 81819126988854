import { useEffect, useState } from "react";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { Card } from "@mui/material";
import { Endpoints, getRequest } from "helpers/api";
import { translate } from "translations/i18n";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "../../../components/Tables/DataTable";
import { petitionReportsTableColumns } from "../../../types/petitionTypes";
import { formatDateWithLocale } from "../../../utils";
import {
  ICommonDocumentProperties,
  IDocuments,
} from "../../../interfaces/petitions";
import {
  displayQuarterlyReportTypeLabel,
  renderActionsQuarterlyReport,
} from "../../../helpers/petitionsHelpers";

const PetitionsQuarterlyReportsList = () => {
  const [quarterlyReport, setQuarterlyReport] = useState<
    ICommonDocumentProperties[]
  >([]);
  const mapQuarterlyReportToTableRow = (
    quarterlyReports: ICommonDocumentProperties[]
  ) => {
    return quarterlyReports
      ? quarterlyReports.map((quarterlyReport) => ({
          id: quarterlyReport.id,
          createdAt: formatDateWithLocale(quarterlyReport.createdAt),
          type: displayQuarterlyReportTypeLabel(quarterlyReport.type),
          name: quarterlyReport.name,
          actions: renderActionsQuarterlyReport(
            quarterlyReport.id,
            displayQuarterlyReportTypeLabel(quarterlyReport.type)
          ),
        }))
      : [];
  };

  useEffect(() => {
    getRequest(`${Endpoints.PETITIONS.QUARTERLY_REPORTS}`)
      .then((quarterlyReportResponse: IDocuments) => {
        setQuarterlyReport(quarterlyReportResponse.documents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {translate("menu.petitionsQuarterlyReportsList")}
              </MDTypography>
            </MDBox>
            <DataTable
              hasSorting={false}
              table={{
                columns: petitionReportsTableColumns,
                rows: mapQuarterlyReportToTableRow(quarterlyReport),
              }}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default PetitionsQuarterlyReportsList;
