import { Navigate } from "react-router-dom";
import { useState } from "react";

import { getToken } from "../../utils";
import AuthLayout from "../../layouts/AuthLayout";
import { translate } from "../../translations/i18n";
import Login from "./components/Login";
import Code2FA from "./components/2FA";

const Auth = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [rememberUser, setRememberUser] = useState<boolean>(false);

  if (getToken()) {
    return <Navigate to="/dashboard" />;
  }

  const handleNextStep = (rememberMe: boolean) => {
    setRememberUser(rememberMe);
    setCurrentStep(1);
  };

  return (
    <AuthLayout
      title={
        currentStep === 0 ? translate("login.signIn") : translate("twoFa.title")
      }
      description={
        currentStep === 0
          ? translate("login.description")
          : translate("twoFa.description")
      }
    >
      {currentStep === 0 ? (
        <Login handleNextStep={handleNextStep} />
      ) : (
        <Code2FA rememberUser={rememberUser} />
      )}
    </AuthLayout>
  );
};

export default Auth;
