import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { translate } from "translations/i18n";

import AuthLayout from "layouts/AuthLayout";
import SetPassword from "features/Onboarding/components/SetPassword";
import Set2FA from "features/Onboarding/components/Set2FA";
import { Endpoints, getRequest } from "helpers/api";

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [otpauthUrl, setOtpauthUrl] = useState<string>();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const getStatus = () => {
    getRequest(`${Endpoints.USER_STATUS}/${code}`)
      .then((res) => {
        switch (res.status) {
          case "ACTIVE":
            navigate("/login");
            break;
          case "MFA_SET":
            navigate("/login");
            break;
          case "ACCOUNT_CREATED":
            setCurrentStep(0);
            break;
          case "PASSWORD_RESET":
            setCurrentStep(0);
            break;
          case "PASSWORD_SET":
            setOtpauthUrl(res.otpauthUrl);
            setCurrentStep(1);
            break;
          case "MFA_RESET":
            setOtpauthUrl(res.otpauthUrl);
            setCurrentStep(1);
            break;
          default:
            toast.error(translate("toastrMessages.inactiveAccountError"));
            break;
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  };

  useEffect(() => {
    getStatus();
  }, [code]);

  return (
    <AuthLayout
      title={
        currentStep === 0
          ? translate("setPassword.title")
          : translate("setTwoFa.title")
      }
      description={
        currentStep === 0
          ? translate("setPassword.description")
          : translate("setTwoFa.description")
      }
    >
      {currentStep === 0 ? (
        <SetPassword emailConfirmationCode={code} callback={getStatus} />
      ) : currentStep === 1 ? (
        <Set2FA emailConfirmationCode={code} otpauthUrl={otpauthUrl} />
      ) : (
        <CircularProgress />
      )}
    </AuthLayout>
  );
};

export default Onboarding;
