import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import roTranslations from "./ro";

const res = {
  ro: {
    translation: roTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources: res,
  lng: "ro",
  interpolation: {
    escapeValue: false,
  },
});

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType]: ObjectType[Key] extends object
    ? // @ts-ignore
      `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : Key;
}[keyof ObjectType];

export function translate<T extends object>(
  name: NestedKeyOf<typeof roTranslations> | string,
  params: any = {}
) {
  return i18n.t(name, params) as unknown as string;
}

export default i18n;
