import { Download, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { NavigateFunction } from "react-router-dom";

import MDBox from "components/mui/MDBox";
import { FiltersData } from "types/dataTableTypes";
import {
  EPetitionerTypeUnderscore,
  EPetitionInsuranceClassUnderscore,
  EPetitionObjectUnderscore,
  EPetitionResolutionEndMode,
  EPetitionResolutionEndReasonUnderscore,
  EPetitionSolicitant,
  EPetitionSourceUnderscore,
  EPetitionStatus,
  EPetitionStatusUnderscore,
  EPetitionType,
  Petition,
} from "types/petitionTypes";
import { EDocumentType } from "../types/documentType";
import { Endpoints } from "./api";
import { downloadFile } from "../services/cardPaymentsServices";

export const renderActions = (row: Petition, navigate: NavigateFunction) => {
  return (
    <MDBox>
      <IconButton
        onClick={() => {
          row.id && navigate(`/petitions/petitionsList/${row.id}`);
        }}
      >
        <Visibility />
      </IconButton>
    </MDBox>
  );
};

export const renderActionsQuarterlyReport = (
  petitionReportId: number,
  filename: string
) => {
  return (
    <MDBox>
      <IconButton
        onClick={() => {
          const url = `${Endpoints.PETITIONS.DOWNLOAD_QUARTERLY_REPORT(
            petitionReportId
          )}`;
          downloadFile(url, filename);
        }}
      >
        <Download />
      </IconButton>
    </MDBox>
  );
};

export const formatFilterModel = (filterModel: FiltersData) => {
  if (!filterModel) {
    return null;
  }

  let modifiedFilterModel = { ...filterModel };
  const processDateRange = (dateRange: DateRange<Date>, key: string) => {
    if (dateRange) {
      if (dateRange[0] && dateRange[1]) {
        modifiedFilterModel = {
          ...modifiedFilterModel,
          startDate: dateRange[0].toISOString(),
          endDate: dateRange[1].toISOString(),
        };
      }
      delete modifiedFilterModel[key];
    }
  };

  if (filterModel.receptionDate) {
    processDateRange(filterModel.receptionDate, "receptionDate");
  }

  if (filterModel.createdAt) {
    processDateRange(filterModel.createdAt, "createdAt");
  }

  return modifiedFilterModel;
};

export const setMinInternalDeadline = (date: Date) => {
  const daysAddedToResolve = 9;

  const resolutionDate = new Date(date.getTime());
  resolutionDate.setDate(date.getDate() + daysAddedToResolve);
  resolutionDate.setHours(0, 0, 0, 0);

  return resolutionDate;
};

export const resetHourDate = (selectedDate: Date) => {
  if (!selectedDate) return;

  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(selectedDate.getTime() - tzoffset).toISOString().slice(0, -1);
};

const createLabelMapping = <T extends string>(
  enumType: Record<string, T>,
  labelFunction: (value: T) => string
): Record<string, string> => {
  return Object.keys(enumType).reduce((acc, key) => {
    const value = enumType[key as keyof typeof enumType];
    acc[value] = labelFunction(value);
    return acc;
  }, {} as Record<string, string>);
};

export const displayPetitionLabel = (petitionType: string): string => {
  switch (petitionType) {
    case EPetitionType.UNIQUE:
      return "Petitie unica";
    case EPetitionType.DUPLICATE:
      return "Petitie identica";
    default:
      return "";
  }
};

export const displayPetitionSolicitantLabel = (
  petitionSolicitant: string
): string => {
  switch (petitionSolicitant) {
    case EPetitionSolicitant.DIRECT:
      return "Direct la societate";
    case EPetitionSolicitant.THIRD_PARTY:
      return "ASF/alte institutii";
    default:
      return "";
  }
};

export const petitionOptions = createLabelMapping(
  EPetitionType,
  displayPetitionLabel
);

export const petitionSolicitantOptions = createLabelMapping(
  EPetitionSolicitant,
  displayPetitionSolicitantLabel
);

export const formatDate = (isoString: string): string => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const displayQuarterlyReportTypeLabel = (
  quarterlyReportType: string
): string => {
  switch (quarterlyReportType) {
    case EDocumentType.VEHICLE_REGISTRATION:
      return "Inregistrare vehicul";
    case EDocumentType.IDENTITY_CARD:
      return "Carte de identitate";
    case EDocumentType.OFFER_REQUEST:
      return "Cerere de oferta";
    case EDocumentType.COMPANY_REGISTRATION:
      return "Inregistrare companie";
    case EDocumentType.OFFER:
      return "Oferta";
    case EDocumentType.INSURANCE_POLICY:
      return "Polita de asigurare";
    case EDocumentType.PAYMENTS_EXPORT:
      return "Export plati";
    case EDocumentType.PETITION_CLIENT_FILE:
      return "Dosar de petiție";
    case EDocumentType.NOTA_FUNDAMENTARE:
      return "Nota fundamentare";
    case EDocumentType.MESAJ_ASF:
      return "Mesaj ASF";
    case EDocumentType.PETITION_RESPONSE:
      return "Raspuns la petiție";
    case EDocumentType.STATEMENT:
      return "Declaratie";
    case EDocumentType.QUARTERLY_REPORT:
      return "Raport trimestrial";
    default:
      return "";
  }
};

export const displayPetitionSourceLabel = (petitionSource: string): string => {
  switch (petitionSource) {
    case EPetitionSourceUnderscore.FORMULAR_ONLINE:
      return "Formular online";
    case EPetitionSourceUnderscore.EMAIL:
      return "Email";
    case EPetitionSourceUnderscore.TELEFON:
      return "Telefon";
    case EPetitionSourceUnderscore.SCRISOARE_RECOMANDATA:
      return "Scrisoare recomandata";
    default:
      return "";
  }
};

export const displayPetitionStatusLabel = (petitionStatus: string): string => {
  switch (petitionStatus) {
    case EPetitionStatusUnderscore.RECEPTIONAT:
      return "Receptionat";
    case EPetitionStatusUnderscore.ALOCAT:
      return "Alocat";
    case EPetitionStatusUnderscore.IN_LUCRU:
      return "In lucru";
    case EPetitionStatusUnderscore.SOLUTIONAT:
      return "Solutionat";
    case EPetitionStatusUnderscore.RESPINS:
      return "Respins";
    case EPetitionStatusUnderscore.INCHIS:
      return "Inchis";
    default:
      return "";
  }
};

export const displayPetitionObjectLabel = (objectType: string): string => {
  switch (objectType) {
    case EPetitionObjectUnderscore.NEPLATA_VALOARE_DESPAGUBIRE_RASCUMPARARE:
      return "Neplata valoare despăgubire/răscumpărare";
    case EPetitionObjectUnderscore.POLITE_DUBLATE_NULE_FALSE_REZILIATE:
      return "Polite dublate/nule/false reziliate";
    case EPetitionObjectUnderscore.RECALCULARE_VALOARE_DESPAGUBIRE_RASCUMPARARE:
      return "Recalculare valoare despăgubire/răscumpărare";
    case EPetitionObjectUnderscore.RECLAMA_NEGATIVA:
      return "Reclamatie negativa";
    case EPetitionObjectUnderscore.SOLICITARE_INFORMATII:
      return "Solicitare informatii";
    case EPetitionObjectUnderscore.CLAUZE_ABUZIVE:
      return "Clauze abuzive";
    case EPetitionObjectUnderscore.NERESPECTAREA_CONDITIILOR_CONTRACTUALE:
      return "Nerespectarea conditiilor contractuale";
    case EPetitionObjectUnderscore.RADIERE_AGENT_ASISTENT:
      return "Radiere agent asistent";
    case EPetitionObjectUnderscore.PLATA_PARTIALA:
      return "Plata partiala";
    case EPetitionObjectUnderscore.REGRES:
      return "Regres";
    case EPetitionObjectUnderscore.POLITA_ASIGURARE:
      return "Polita asigurare";
    case EPetitionObjectUnderscore.ALTELE:
      return "Altele";
    default:
      return "";
  }
};

export const displayPetitionerTypeLabel = (petitionerType: string): string => {
  switch (petitionerType) {
    case EPetitionerTypeUnderscore.PAGUBIT:
      return "Pagubit";
    case EPetitionerTypeUnderscore.ASIGURAT:
      return "Asigurat";
    case EPetitionerTypeUnderscore.REPREZENTANT_PAGUBIT:
      return "Reprezentant pagubit";
    case EPetitionerTypeUnderscore.REPREZENTANT_ASIGURAT:
      return "Reprezentant asigurat";
    case EPetitionerTypeUnderscore.SERVICE:
      return "Service";
    case EPetitionerTypeUnderscore.BROKER_AGENT:
      return "Broker agent";
    case EPetitionerTypeUnderscore.NU_STIU:
      return "Nu stiu";
    default:
      return "";
  }
};

export const displayPetitionResolutionEndModeLabel = (
  resolutionEndMode: string
): string => {
  switch (resolutionEndMode) {
    case EPetitionResolutionEndMode.FAVORABIL:
      return "Favorabil";
    case EPetitionResolutionEndMode.NEINTEMEIAT:
      return "Neintemeiat";
    default:
      return "";
  }
};

export const displayPetitionResolutionEndReasonLabel = (
  reason: string
): string => {
  switch (reason) {
    case EPetitionResolutionEndReasonUnderscore.ALTELE:
      return "Altele";
    case EPetitionResolutionEndReasonUnderscore.AVARII_PREEXISTENTE:
      return "Avarii preexistente";
    case EPetitionResolutionEndReasonUnderscore.CERCETARI_SUPLIMENTARE_IN_CURS:
      return "Cercetari suplimentare in curs";
    case EPetitionResolutionEndReasonUnderscore.CLAUZE_EXCLUDERE:
      return "Clauze de excludere";
    case EPetitionResolutionEndReasonUnderscore.CULPA_COMUNA:
      return "Culpa comuna";
    case EPetitionResolutionEndReasonUnderscore.DAUNE_NECONSTATATE:
      return "Daune neconstatate";
    case EPetitionResolutionEndReasonUnderscore.DOSAR_CERCETARE_PENALA:
      return "Dosar cercetare penala";
    case EPetitionResolutionEndReasonUnderscore.DOSAR_INCOMPLET:
      return "Dosar incomplet";
    case EPetitionResolutionEndReasonUnderscore.DOSAR_PRESCRIS:
      return "Dosar prescris";
    case EPetitionResolutionEndReasonUnderscore.EXPERTIZA_TEHNICA:
      return "Expertiza tehnica";
    case EPetitionResolutionEndReasonUnderscore.INVESTIGATII_PROPRII:
      return "Investigatii proprii";
    case EPetitionResolutionEndReasonUnderscore.LIPSA_CALITATE:
      return "Lipsa calitate";
    case EPetitionResolutionEndReasonUnderscore.POLITA_NEVALABILA:
      return "Polita nevalabila";
    case EPetitionResolutionEndReasonUnderscore.RECALCULARE_VALOARE_DESBAGUBIRE_RASCUMPARARE:
      return "Recalculare valoare desbagubire/rascumparare";
    case EPetitionResolutionEndReasonUnderscore.RISCURI_NEACOPERITE:
      return "Riscuri neacoperite";
    default:
      return "";
  }
};

export const displayPetitionInsuranceClassLabel = (
  insuranceClass: string
): string => {
  switch (insuranceClass) {
    case EPetitionInsuranceClassUnderscore.ACCIDENTE:
      return "Accidente";
    case EPetitionInsuranceClassUnderscore.SANATATE:
      return "Sanatate";
    case EPetitionInsuranceClassUnderscore.VEHICULE_TERESTRE:
      return "Vehicule terestre";
    case EPetitionInsuranceClassUnderscore.NAVE_MARITIME:
      return "Nave maritime";
    case EPetitionInsuranceClassUnderscore.BUNURI_TRANZIT:
      return "Bunuri in tranzit";
    case EPetitionInsuranceClassUnderscore.INCENDIU_CALAMITATI_NATURALE:
      return "Incendiu si calamitati naturale";
    case EPetitionInsuranceClassUnderscore.ALTE_DAUNE:
      return "Alte daune";
    case EPetitionInsuranceClassUnderscore.CREDIT:
      return "Credit";
    case EPetitionInsuranceClassUnderscore.GARANTII:
      return "Garantii";
    case EPetitionInsuranceClassUnderscore.PIERDERI_FINANCIARE:
      return "Pierderi financiare";
    default:
      return "";
  }
};

enum EPetitionResolveStatus {
  FINALIZATA = "FINALIZATA",
  IN_CURS_DE_SOLUTIONARE = "IN CURS DE SOLUTIONARE",
}

export const mapStatusToResolveStatus = (status: string | string[]) => {
  switch (status) {
    case EPetitionStatus.RECEPTIONAT:
    case EPetitionStatus.ALOCAT:
    case EPetitionStatus.IN_LUCRU:
    case "IN_LUCRU":
      return EPetitionResolveStatus.IN_CURS_DE_SOLUTIONARE;

    case EPetitionStatus.SOLUTIONAT:
      return EPetitionResolveStatus.FINALIZATA;

    default:
      return "";
  }
};

export enum PetitionTabIndex {
  PetitionContentTab = 0,
  PetitionUpdatesTab = 1,
  PetitionClientResponseTab = 2,
}
