import React, { useState } from "react";
import { toast } from "react-toastify";

import MDBox from "components/mui/MDBox";
import MDInput from "components/mui/MDInput";
import { translate } from "translations/i18n";
import MDButton from "components/mui/MDButton";
import { Endpoints, postRequest } from "helpers/api";

interface Props {
  emailConfirmationCode: string;
  callback: () => void;
}

const SetPassword = (props: Props) => {
  const { emailConfirmationCode, callback } = props;

  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const handleSetPassword = () => {
    if (password !== confirmPassword) {
      toast.error("toastrMessages.differentConfirmPassword");
      return;
    }

    postRequest(Endpoints.SET_PASSWORD, {
      emailConfirmationCode,
      password,
    })
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MDBox component="form" role="form">
      <MDBox mb={2}>
        <MDInput
          type="password"
          value={password || ""}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPassword(e.target.value);
          }}
          label={translate("setPassword.passwordLabel")}
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="password"
          value={confirmPassword || ""}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setConfirmPassword(e.target.value);
          }}
          label={translate("setPassword.confirmPasswordLabel")}
          fullWidth
        />
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton
          variant="gradient"
          color="info"
          size="large"
          fullWidth
          onClick={handleSetPassword}
          disabled={!(confirmPassword && password)}
        >
          {translate("setPassword.buttonLabel")}
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default SetPassword;
