import { Navigate } from "react-router-dom";
import {
  AdminPanelSettings,
  People,
  Dashboard as DashboardIcon,
  PersonAddAlt1,
  FormatListBulleted,
  LibraryAdd,
  Person,
  Payments,
  Paid,
  LocalAtm,
  ShortText,
} from "@mui/icons-material";

import Dashboard from "./features/Dashboard";
import RolesList from "features/Roles/RolesList";
import AddRole from "features/Roles/AddRole";
import RoleDetails from "features/Roles/RoleDetails";
import AddUser from "features/Users/AddUser";
import UsersList from "features/Users/UsersList";
import UserDetails from "features/Users/UserDetails";
import Auth from "features/Auth";
import Onboarding from "features/Onboarding";
import { IRoute } from "interfaces";
import CardPayments from "features/CardPayments";
import AddPetitions from "./features/Petitions/AddPetitions";
import PetitionsList from "features/Petitions/PetitionsList";
import PetitionDetails from "./features/Petitions/PetitionDetails";
import PetitionRegistry from "features/Petitions/PetitionRegistry";
import PetitionsQuarterlyReportsList from "./features/Petitions/PetitionsQuarterlyReportList";
import RequestPasswordReset from "./features/RequestPasswordReset";
import ChangePassword from "./features/ChangePassword";

const Logout = () => {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  return <Navigate to="/login" />;
};

const routes: IRoute[] = [
  {
    type: "collapse",
    key: "account",
    icon: <Person fontSize="medium" />,
    collapse: [
      {
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  {
    key: "onboarding",
    route: "/onboarding",
    hide: true,
    component: <Onboarding />,
  },
  {
    key: "login",
    route: "/login",
    hide: true,
    component: <Auth />,
  },
  {
    key: "reset-password-request",
    route: "/reset-password-request",
    hide: true,
    component: <RequestPasswordReset />,
  },
  {
    key: "reset-password",
    route: "/reset-password",
    hide: true,
    component: <ChangePassword />,
  },
  {
    key: "myaccount",
    route: "myaccount",
    component: <Dashboard />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    key: "dashboard",
    icon: <DashboardIcon fontSize="medium" />,
    noCollapse: true,
    private: true,
    route: "/dashboard",
    component: <Dashboard />,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    key: "petitions",
    private: true,
    icon: <ShortText fontSize="medium" />,
    collapse: [
      {
        key: "addPetitions",
        icon: <LibraryAdd fontSize="medium" />,
        route: "/petitions/addPetitions",
        component: <AddPetitions />,
        private: true,
      },
      {
        key: "petitionsList",
        icon: <FormatListBulleted fontSize="medium" />,
        route: "/petitions/petitionsList",
        component: <PetitionsList />,
        private: true,
      },
      {
        key: "petitionDetails",
        route: "/petitions/petitionsList/:petitionId",
        hide: true,
        private: true,
        component: <PetitionDetails />,
      },
      {
        key: "registry",
        icon: <FormatListBulleted fontSize="medium" />,
        route: "/petitions/registry",
        component: <PetitionRegistry />,
        private: true,
      },
      {
        key: "petitionsQuarterlyReportsList",
        icon: <FormatListBulleted fontSize="medium" />,
        route: "/petitions/petitionsQuarterlyReportsList",
        component: <PetitionsQuarterlyReportsList />,
        private: true,
      },
    ],
  },
  {
    type: "collapse",
    key: "payments",
    private: true,
    icon: <Paid fontSize="medium" />,
    collapse: [
      {
        key: "cardpaymentslist",
        icon: <Payments fontSize="medium" />,
        route: "/payments/cardpaymentslist",
        component: <CardPayments />,
        private: true,
      },
      {
        key: "oppaymentslist",
        icon: <LocalAtm fontSize="medium" />,
        route: "/payments/oppaymentslist",
        component: <Dashboard />,
        private: true,
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    key: "users",
    private: true,
    icon: <People fontSize="medium" />,
    collapse: [
      {
        key: "adduser",
        icon: <PersonAddAlt1 fontSize="medium" />,
        route: "/users/adduser",
        component: <AddUser />,
        private: true,
      },
      {
        key: "userslist",
        icon: <FormatListBulleted fontSize="medium" />,
        route: "/users/userslist",
        private: true,
        component: <UsersList />,
      },
      {
        key: "userdetails",
        route: "/users/userdetails/:id",
        hide: true,
        private: true,
        component: <UserDetails />,
      },
    ],
  },
  {
    type: "collapse",
    key: "roles",
    private: true,
    icon: <AdminPanelSettings fontSize="medium" />,
    collapse: [
      {
        key: "addrole",
        icon: <LibraryAdd fontSize="medium" />,
        route: "/roles/addrole",
        private: true,
        component: <AddRole />,
      },
      {
        key: "roleslist",
        icon: <FormatListBulleted fontSize="medium" />,
        route: "/roles/roleslist",
        private: true,
        component: <RolesList />,
      },
      {
        key: "roledetails",
        route: "/roles/roledetails/:id",
        hide: true,
        private: true,
        component: <RoleDetails />,
      },
    ],
  },
];

export default routes;
