import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash/debounce";

import MDInput from "../MDInput";
import { Endpoints, getRequest } from "../../../helpers/api";
import { IPetition } from "../../../interfaces/petitions";
import { AutocompleteOption, SearchAutocompleteProps } from "interfaces";
import { FormHelperText } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

const MDSearchDuplicatePetitionId: React.FC<SearchAutocompleteProps> = ({
  id,
  name,
  onChange,
  handleOnBlur,
  label,
  error,
  helperText,
  disabled,
}) => {
  const [value, setValue] = useState<AutocompleteOption | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [initializationComplete, setInitializationComplete] =
    useState<boolean>(false);

  const getDuplicatedPetitions = useCallback(
    debounce((searchTerm: string) => {
      getRequest(`${Endpoints.PETITIONS.DUPLICATED}`, {
        searchTerm: searchTerm || undefined,
      })
        .then((duplicates: IPetition[]) => {
          const formattedOptions = duplicates.map((item: IPetition) => ({
            value: item.petitionId,
            receptionDate: item.receptionDate,
            label: `${item.petitionId}/${
              item.petitionObject
                ? item.petitionObject.replace(/_/g, " ") + "/"
                : ""
            }${item.petitionerName}`,
          }));
          setOptions(formattedOptions);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 300),
    []
  );

  useEffect(() => {
    getDuplicatedPetitions("");

    return () => {
      getDuplicatedPetitions.cancel();
    };
  }, [getDuplicatedPetitions]);

  useEffect(() => {
    if (!initializationComplete && id && options.length) {
      const preSelectedOption = options.find((option) => option.value === id);
      if (preSelectedOption) {
        setValue(preSelectedOption);
        setInputValue(preSelectedOption.label);
        onChange(preSelectedOption);
      }
      setInitializationComplete(true);
    }
  }, [id, options, initializationComplete]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    setInputValue(newValue);
    getDuplicatedPetitions(newValue);
  };

  const getOptionLabel = (option: AutocompleteOption | string) => {
    if (typeof option === "string") {
      return option;
    }
    return option.label;
  };

  return (
    <Autocomplete
      value={value || null}
      sx={{ height: "45px" }}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          setInputValue(newValue);
          setValue(null);
          onChange(null);
        } else {
          setValue(newValue);
          onChange(newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => handleInputChange(_, newInputValue)}
      options={options}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <>
          <MDInput
            {...params}
            label={label}
            name={name}
            variant="outlined"
            onBlur={handleOnBlur}
            error={error}
            disabled={disabled}
            InputProps={{
              error: error,
              ...params.InputProps,
            }}
          />
          {error && (
            <FormHelperText
              sx={{ color: "red", margin: `${pxToRem(3)} ${pxToRem(14)}` }}
            >
              {helperText}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default MDSearchDuplicatePetitionId;
