import AuthLayout from "../../layouts/AuthLayout";
import MDBox from "../../components/mui/MDBox";
import MDInput from "../../components/mui/MDInput";
import React, { useState } from "react";
import { translate } from "../../translations/i18n";
import MDButton from "../../components/mui/MDButton";
import { Endpoints, postRequest } from "../../helpers/api";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

const ChangePassword = () => {
  const [password, setPassword] = useState<string>("");
  const [confimPassword, setConfirmPassword] = useState<string>("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const requestResetPassword = async () => {
    if (password !== confimPassword) {
      toast.error("Parolele nu sunt identice");
      return;
    }

    await postRequest(Endpoints.RESET_PASSWORD, {
      resetPasswordToken: code,
      password: password,
    })
      .then((res) => {
        toast.success("Parola a fost schimbata cu succes");
        navigate(`/onboarding?code=${res.emailConfirmationCode}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AuthLayout
      title={translate("changePassword.title")}
      description={translate("changePassword.description")}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="password"
            value={password || ""}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setPassword(e.target.value);
            }}
            label={translate("login.passwordLabel")}
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            value={confimPassword || ""}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setConfirmPassword(e.target.value);
            }}
            label={translate("login.confirmPasswordLabel")}
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            onClick={requestResetPassword}
          >
            {translate("changePassword.buttonLabel")}
          </MDButton>
        </MDBox>
      </MDBox>
    </AuthLayout>
  );
};

export default ChangePassword;
