import { toast } from "react-toastify";
import { getRequest } from "helpers/api";
import { getFileName } from "helpers/exportFileHelpers";
import { FiltersData } from "types/dataTableTypes";

export function downloadFile(
  url: string,
  fileTitle?: string,
  filters: FiltersData = {}
) {
  getRequest(url, filters, { responseType: "blob" }, "arraybuffer")
    .then((response) => {
      const filename = getFileName(fileTitle);
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = filename;
      link.click();
    })
    .catch(() => {
      toast.error("A aparut o eroare");
    });
}
