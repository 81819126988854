import { ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import MDButton from "components/mui/MDButton";
import MDBox from "components/mui/MDBox";

interface ConfirmationModalProps {
  title: string;
  description: string;
  handleSubmit: () => void;
  children?: ReactNode;
  open: boolean;
  handleClose: () => void;
}

const ConfirmationModal = (props: ConfirmationModalProps): JSX.Element => {
  const { title, description, handleSubmit, children, open, handleClose } =
    props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="body1">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{description}</Typography>
        {children}
      </DialogContent>
      <DialogActions>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "0.5rem",
          }}
        >
          <MDButton variant="outlined" color="info" onClick={handleClose}>
            Anulează
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={() => {
              handleSubmit();
            }}
          >
            Ok
          </MDButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
