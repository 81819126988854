import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash/debounce";

import MDInput from "../MDInput";
import { Endpoints, getRequest } from "../../../helpers/api";
import { IAgent } from "../../../interfaces/petitions";

interface AutocompleteOption {
  value: number;
  label: string;
  petitionsCount: number;
}

interface AgentSearchProps {
  label: string;
  name: string;
  selectedAgentId?: number;
  onChange: (selectedOption?: AutocompleteOption) => void;
  shouldResetInput?: boolean;
}

const defaultSearchParams = {
  sortBy: "id",
  sortDir: "ASC",
  page: 1,
  perPage: 10,
};

const AgentSearch: React.FC<AgentSearchProps> = ({
  selectedAgentId,
  onChange,
  shouldResetInput,
  label,
}) => {
  const [value, setValue] = useState<AutocompleteOption | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [initializationComplete, setInitializationComplete] =
    useState<boolean>(false);

  const fetchAgents = useCallback(
    debounce(async (searchTerm: string) => {
      try {
        const params = {
          ...defaultSearchParams,
          search: searchTerm,
        };

        const response = await getRequest(
          `${Endpoints.PETITIONS.AGENTS}`,
          params
        );

        const formattedOptions = response.agents.map((agent: IAgent) => ({
          value: agent.id,
          label: agent.name,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (shouldResetInput) {
      setInputValue("");
      setValue(null);
      onChange(null);
    }
  }, [shouldResetInput, onChange]);

  useEffect(() => {
    fetchAgents("");

    return () => {
      fetchAgents.cancel();
    };
  }, [fetchAgents]);

  useEffect(() => {
    if (!initializationComplete && selectedAgentId && options.length) {
      const preSelectedOption = options.find(
        (option) => option.value === selectedAgentId
      );
      if (preSelectedOption) {
        setValue(preSelectedOption);
        setInputValue(preSelectedOption.label);
      }
      setInitializationComplete(true);
    }
  }, [selectedAgentId, options, initializationComplete]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    setInputValue(newValue);
    fetchAgents(newValue);
  };

  const getOptionLabel = (option: AutocompleteOption | string) =>
    typeof option === "string" ? option : option.label;

  return (
    <>
      <Autocomplete
        id="agent-search-autocomplete"
        freeSolo
        value={value || null}
        sx={{ height: "45px" }}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            setInputValue(newValue);
            setValue(null);
            onChange(null);
          } else {
            setValue(newValue);
            onChange(newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) =>
          handleInputChange(_, newInputValue)
        }
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <MDInput
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </>
  );
};

export default AgentSearch;
