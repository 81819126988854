import { DateRange } from "@mui/x-date-pickers-pro";
import { FilterField } from "./dataTableTypes";

export interface IDateRangePickerItem {
  field: string;
  id: number;
  operator: string;
  value: DateRange<Date> | [null, null];
}

export const DEFAULT_PAGINATION_MODEL = {
  itemsPerPage: 10,
  page: 1,
};

export enum PaymentStatuses {
  ISSUED = "ISSUED",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
}

export enum PaymentTypes {
  OP = "OP",
  CARD_NETOPIA = "CARD NETOPIA",
  CARD_STRIPE = "CARD STRIPE",
}

export enum GdprChannels {
  SELF_SERVICE = "SELF SERVICE",
  VANZARE_ASISTATA = "VANZARE ASISTATA",
}

export const paymentTableColumns = [
  { accessor: "id", Header: "Offer No" },
  {
    accessor: "status",
    Header: "Status",
  },
  {
    accessor: "paymentType",
    Header: "Payment Type",
  },
  {
    accessor: "amount",
    Header: "Amount",
  },
  {
    accessor: "gdprChannel",
    Header: "Channels",
  },
  {
    accessor: "createdAt",
    Header: "Created At",
  },
  {
    accessor: "updatedAt",
    Header: "Updated At",
  },
];

export const paymentFilters = [
  {
    name: "status",
    Header: "Status",
    type: FilterField.SELECT,
    valueOptions: PaymentStatuses,
    multiselect: true,
  },
  {
    name: "paymentType",
    Header: "Payment Type",
    width: 160,
    type: FilterField.SELECT,
    valueOptions: PaymentTypes,
    multiselect: true,
  },
  {
    name: "amount",
    Header: "Amount",
    type: FilterField.SEARCH,
  },
  {
    name: "gdprChannel",
    Header: "Channels",
    type: FilterField.SELECT,
    valueOptions: GdprChannels,
    multiselect: true,
  },
  {
    name: "createdAt",
    Header: "Created At",
    type: FilterField.DATE_INTERVAL,
  },
];
