export const getFileName = (title?: string) => {
  const day = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const lastDotIndex = title ? title.lastIndexOf(".") : -1;

  if (lastDotIndex === -1 || !title) {
    const fileName = title || "File";
    const extension = "csv";

    return `${fileName} - ${day}-${month}-${year}.${extension}`;
  } else {
    return title;
  }
};
