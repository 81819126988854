import ReactQuill, { ReactQuillProps } from "react-quill";
import { useMaterialUIController } from "context";
import "react-quill/dist/quill.snow.css";
import { FormHelperText } from "@mui/material";

import MDEditorRoot from "components/mui/MDEditor/MDEditorRoot";
import pxToRem from "assets/theme/functions/pxToRem";
import { CustomEvent } from "interfaces/petitions";
import colors from "assets/theme/base/colors";

interface Props {
  content?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (content: string) => void;
  handleOnBlur?: (e: CustomEvent) => void;
  [key: string]: any;
}

function MDEditor({
  content,
  label,
  disabled,
  onChange,
  handleOnBlur,
  error,
  helperText,
  ...otherProps
}: Props & ReactQuillProps): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { text } = colors;

  const modules = {
    toolbar: false,
  };

  const handleChange = (content: string) => {
    if (onChange) {
      onChange(content);
    }
  };

  const handleEditorBlur = () => {
    // necessary to validate in formik if there is content on the onBlur event
    const customEvent = {
      target: {
        name: "petitionContent",
      },
    };

    if (handleOnBlur) {
      handleOnBlur(customEvent);
    }
  };

  return (
    <MDEditorRoot ownerState={{ error, darkMode }}>
      <label style={{ color: disabled ? "rgba(0, 0, 0, 0.38)" : text.main }}>
        {label}
      </label>
      {disabled ? (
        <ReactQuill
          value={content}
          modules={modules}
          readOnly
          {...otherProps}
        />
      ) : (
        <ReactQuill
          theme="snow"
          value={content}
          onChange={handleChange}
          onBlur={handleEditorBlur}
          {...otherProps}
        />
      )}
      {error && (
        <FormHelperText
          sx={{ color: "red", margin: `${pxToRem(3)} ${pxToRem(14)}` }}
        >
          {helperText}
        </FormHelperText>
      )}
    </MDEditorRoot>
  );
}

export default MDEditor;
