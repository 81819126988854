import Card from "@mui/material/Card";
import { Done, Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { toast } from "react-toastify";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { roleChangelogTableColumns, roleUsersTableColumns } from "helpers";
import { IPermission, IRole, IRoleChangelogItem, IUser } from "interfaces";
import { translate } from "translations/i18n";
import { formatDateWithLocale, getDifference } from "utils/utilsFunctions";
import { Endpoints, getRequest, patchRequest } from "helpers/api";

const RoleDetails = () => {
  const [role, setRole] = useState<IRole>();
  const [allPermissions, setAllPermissions] = useState<IPermission[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [changelog, setChangelog] = useState<IRoleChangelogItem[]>([]);

  const location = useLocation();
  const state = location.state as { edit?: boolean };
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getRequest(`${Endpoints.ROLES}/${id}`)
      .then((res) => {
        setRole(res);
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest(`${Endpoints.USER}/role/${id}`)
      .then((res) => {
        setUsers(res);
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest(Endpoints.PERMISSIONS)
      .then((res) => {
        setAllPermissions(res);
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest(`${Endpoints.ROLES}/${id}/changelogs`)
      .then((res) => {
        setChangelog(res);
      })
      .catch((error) => {
        console.log(error);
      });
    state && state?.edit && setIsEditMode(true);
  }, []);

  const availablePermissions = getDifference(
    allPermissions,
    role?.permissions,
    "code"
  );

  const handleRemovePermission = (permission: IPermission) => {
    const rolePermissions = role.permissions.filter(
      (p) => permission.code !== p.code
    );
    setRole({ ...role, permissions: rolePermissions });
  };

  const handleAddPermission = (permission: IPermission) => {
    const rolePermissions = [...role.permissions, permission];
    setRole({ ...role, permissions: rolePermissions });
  };

  const handleEdit = () => {
    patchRequest(`${Endpoints.ROLES}/${id}`, role)
      .then(() => {
        toast.success(translate("toastrMessages.successUpdateRole"));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {role?.name}
              </MDTypography>
              {isEditMode ? (
                <IconButton
                  color="success"
                  onClick={() => {
                    handleEdit();
                    setIsEditMode(false);
                  }}
                >
                  <Done fontSize="medium" />
                </IconButton>
              ) : (
                <IconButton
                  color="success"
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                >
                  <Edit fontSize="medium" />
                </IconButton>
              )}
            </MDBox>
            <MDBox px={3} mb={3} display="flex" gap={2} alignItems="flex-start">
              <MDTypography
                variant="h6"
                fontWeight="regular"
                sx={{ whiteSpace: "nowrap", width: "auto" }}
              >
                {role?.permissions.length
                  ? `${translate("roles.roleCurrentPermissions")}: `
                  : translate("roles.noCurrentPermissions")}
              </MDTypography>
              <MDBox display="flex" gap={2} alignItems="center" flexWrap="wrap">
                {role?.permissions.map((p) => (
                  <Chip
                    label={p.code}
                    key={p.code}
                    onDelete={() => {
                      isEditMode && handleRemovePermission(p);
                    }}
                  />
                ))}
              </MDBox>
            </MDBox>
            {isEditMode && (
              <MDBox
                px={3}
                mb={3}
                display="flex"
                gap={2}
                alignItems="flex-start"
              >
                <MDTypography
                  variant="h6"
                  fontWeight="regular"
                  sx={{ whiteSpace: "nowrap", width: "auto" }}
                >
                  {availablePermissions.length
                    ? `${translate("roles.roleAvailablePermissions")}: `
                    : translate("roles.noAvailablePermissions")}
                </MDTypography>

                <MDBox
                  display="flex"
                  gap={2}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {availablePermissions.map((p) => (
                    <Chip
                      label={p.code}
                      key={p.code}
                      onClick={
                        isEditMode &&
                        (() => {
                          handleAddPermission(p);
                        })
                      }
                    />
                  ))}
                </MDBox>
              </MDBox>
            )}
          </Card>
        </MDBox>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {translate("roles.userWithRole")} {role?.name}
              </MDTypography>
            </MDBox>
            <DataTable
              hasSorting={false}
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: roleUsersTableColumns,
                rows: users,
              }}
            />
          </Card>
        </MDBox>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {translate("roles.roleChangelogTableTitle")} {role?.name}
              </MDTypography>
            </MDBox>
            <DataTable
              hasSorting={false}
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: roleChangelogTableColumns,
                rows: changelog.map((c) => ({
                  ...c,
                  createdAt: formatDateWithLocale(c.createdAt),
                })),
              }}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default RoleDetails;
