import React, { ChangeEvent, useEffect, useState } from "react";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import colors from "assets/theme/base/colors";

type CustomSelectProps = {
  name: string;
  label: string;
  options: { [key: string]: string };
  value?: string | string[];
  disabled?: boolean;
  multiselect?: boolean;
  onChange?: (event: SelectChangeEvent<string | string[]>) => void;
  handleOnBlur?: (e: ChangeEvent) => void;
  error?: boolean;
  helperText?: string;
  colorMap?: { [key: string]: string };
};

const MDDropdown: React.FC<CustomSelectProps> = ({
  label,
  name,
  options,
  value,
  multiselect,
  onChange,
  handleOnBlur,
  error,
  helperText,
  disabled,
  colorMap,
}) => {
  const [localValue, setLocalValue] = useState<string | string[]>(
    value || (multiselect ? [] : "")
  );
  const { text } = colors;

  const getColor = (value: string | string[]): string => {
    if (Array.isArray(value)) {
      if (colorMap && colorMap[value[0]]) {
        return colorMap[value[0]];
      }
    } else if (colorMap && colorMap[value]) {
      return colorMap[value];
    }
    return "black"; // default color
  };

  const currentColor = getColor(value || localValue);

  useEffect(() => {
    // Update localValue when the value prop changes externally
    setLocalValue(value || (multiselect ? [] : ""));
  }, [value, multiselect]);

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const selectedValue = event.target.value;
    setLocalValue(selectedValue);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel sx={{ color: disabled ? "rgba(0, 0, 0, 0.38)" : text.main }}>
        {label}
      </InputLabel>
      <Select
        multiple={multiselect}
        sx={{
          color: currentColor,
          height: "45px",
        }}
        disabled={disabled}
        value={localValue}
        onChange={handleSelectChange}
        onBlur={handleOnBlur}
        label={label}
        name={name}
      >
        {Object.keys(options).map((key, index) => (
          <MenuItem
            key={index}
            value={key}
            sx={{
              color: getColor(key),
            }}
          >
            {options[key]}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default MDDropdown;
