import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";

import OTPCodeInput from "components/OTPCodeInput";
import MDBox from "components/mui/MDBox";
import MDButton from "components/mui/MDButton";
import { translate } from "translations/i18n";
import { Endpoints, postRequest } from "helpers/api";

interface Props {
  emailConfirmationCode: string;
  otpauthUrl: string;
}

const Set2FA = (props: Props) => {
  const { emailConfirmationCode, otpauthUrl } = props;

  const [code, setCode] = useState<string>();
  const [qrImage, setQrImage] = useState<string>();

  const navigate = useNavigate();

  const handleSet2Fa = () => {
    postRequest(Endpoints.SET_OTP, { emailConfirmationCode, code })
      .then(() => {
        navigate("login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    QRCode.toDataURL(otpauthUrl).then(setQrImage);
  }, []);

  return (
    <MDBox textAlign="center">
      <img src={qrImage} alt="authqrcode" />
      <OTPCodeInput
        onChange={(c) => {
          setCode(c);
        }}
      />
      <MDBox mt={4} mb={1}>
        <MDButton
          variant="gradient"
          color="info"
          size="large"
          fullWidth
          onClick={handleSet2Fa}
          disabled={!code}
        >
          {translate("setTwoFa.buttonLabel")}
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default Set2FA;
