import { useMemo, useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDInput from "components/mui/MDInput";
import MDPagination from "components/mui/MDPagination";
import DataTableHeadCell from "components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Tables/DataTable/DataTableBodyCell";
import { translate } from "../../../translations/i18n";
import FiltersWrapper from "components/FiltersWrapper";
import { usePaginationPages } from "./usePaginationPages";
import pxToRem from "assets/theme/functions/pxToRem";
import { FiltersData, SortingModel } from "types/dataTableTypes";
import { DEFAULT_PAGINATION_MODEL } from "types/cardPaymentsTypes";
import { formatFilterModel } from "helpers/petitionsHelpers";
import { replaceUnderscoresWithSpaces } from "helpers/dataTableHelpers";

const Pagination = ({
  gotoPage,
  length,
  pageSize,
  setPageSize,
  onPaginationChange,
  entries,
}: {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  length: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  pagination: { variant: string; color: string };
  entries: number[];
}) => {
  const [perPage, setPerPage] = useState(pageSize);

  const {
    canGo,
    currentPage,
    pages,
    goTo,
    goNext,
    goPrev,
    entriesStart,
    entriesEnd,
  } = usePaginationPages({
    gotoPage,
    length,
    pageSize,
    onPaginationChange,
  });

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage]);

  return (
    <MDBox p={3} width="100%">
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" alignItems="center">
          <Autocomplete
            disableClearable
            value={pageSize.toString()}
            options={entries}
            onChange={(event, newValue: string | number) => {
              setPerPage(+newValue);
            }}
            size="small"
            sx={{ width: pxToRem(80) }}
            renderInput={(params) => <MDInput {...params} />}
          />
          <MDTypography variant="caption" color="secondary">
            &nbsp;&nbsp;{translate("dataTable.entriesPerPage")}
          </MDTypography>
        </MDBox>
        <MDBox mb={{ xs: 3, sm: 0 }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            {translate("dataTable.from")} {entriesStart}
            {translate("dataTable.to")} {entriesEnd} {translate("dataTable.of")}
            {length} {translate("dataTable.entries")}
          </MDTypography>
        </MDBox>
      </MDBox>

      {pages.length > 1 && (
        <MDBox display="flex" justifyContent="center" pt={2}>
          <MDPagination>
            <MDPagination disabled={!canGo.previous} item onClick={goPrev}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
            {pages.map((page, i) => (
              <MDPagination
                item
                key={page}
                onClick={() => goTo(page)}
                active={currentPage === page}
              >
                {page}
              </MDPagination>
            ))}
            <MDPagination disabled={!canGo.next} item onClick={goNext}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          </MDPagination>
        </MDBox>
      )}
    </MDBox>
  );
};

// Declaring props types for DataTable
interface Props {
  hasSorting?: boolean;
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: "contained" | "gradient";
    color:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "dark"
      | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  filters?: FiltersData[];
  totalItems?: number;
  canSearch?: boolean;
  hasExportCSV?: boolean;
  onFiltersChange?: (value: FiltersData) => void;
  handleExportCSV?: (filters: FiltersData) => void;
}

function DataTable({
  hasSorting,
  entriesPerPage,
  table,
  pagination,
  isSorted,
  noEndBorder,
  filters,
  totalItems,
  canSearch = false,
  hasExportCSV = false,
  onFiltersChange,
  handleExportCSV,
}: Props): JSX.Element {
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  const [filterModel, setFilterModel] = useState({});
  const [sortingModel, setSortingModel] = useState<SortingModel[]>([]);

  let defaultValue: any;
  let entries: any[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue
      ? entriesPerPage.defaultValue
      : "10";
    entries = entriesPerPage.entries
      ? entriesPerPage.entries
      : ["10", "25", "50", "100"];
  }

  // Iterate through the array and replace underscores with spaces
  for (const object of table.rows) {
    replaceUnderscoresWithSpaces(object);
  }

  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Set initial page values
      manualPagination: true,
      manualSortBy: true,
      disableSortBy: !hasSorting,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize, sortBy },
  }: any = tableInstance;

  const generateSortFieldsArray = (sortingModel: SortingModel[]) => {
    return sortingModel.map((sortItem: SortingModel) => ({
      field: sortItem.field,
      direction: sortItem.direction,
    }));
  };

  const formatSortFields = (sortFieldsArray: SortingModel[]) => {
    let params: Record<string, string> = {};
    sortFieldsArray.forEach((sortField: SortingModel) => {
      params["sortField"] = sortField.field;
      params["sortDir"] = sortField.direction;
    });
    return params;
  };

  useEffect(() => {
    const formattedFilters = formatFilterModel(filterModel);
    const sortFieldsArray = generateSortFieldsArray(sortingModel) || undefined;
    const sortFields = formatSortFields(sortFieldsArray);

    const combinedData = {
      ...formattedFilters,
      ...paginationModel,
      ...sortFields,
    };
    if (onFiltersChange) onFiltersChange(combinedData);
  }, [filterModel, paginationModel, sortingModel]);

  useEffect(() => {
    const newSortingModel = [...sortingModel];
    sortBy.forEach((item: { id: string; desc: boolean }) => {
      const { id, desc } = item;
      const direction = desc ? "DESC" : "ASC";
      const existingIndex = newSortingModel.findIndex((x) => x.field === id);

      if (existingIndex > -1) {
        newSortingModel[existingIndex].direction = direction;
      } else {
        newSortingModel.push({ field: id, direction });
      }
    });

    setSortingModel(newSortingModel);
  }, [sortBy]);

  const downloadCSV = (filters: FiltersData) => {
    if (handleExportCSV) handleExportCSV(filters);
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox gap={1}>
        <FiltersWrapper
          filteringFields={filters}
          canSearch={canSearch}
          onFilterChange={(filters) => setFilterModel(filters)}
          handleExportCSV={downloadCSV}
          hasExportCSV={hasExportCSV}
        />
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  sorted={hasSorting}
                  key={key}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    width={cell.column?.style?.width}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {totalItems ? ( // this is not the best validation
        <Pagination
          gotoPage={gotoPage}
          length={totalItems}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPaginationChange={(pageIndex, pageSize) =>
            setPaginationModel({ itemsPerPage: pageSize, page: pageIndex })
          }
          pagination={pagination}
          entries={entries}
        />
      ) : (
        !rows.length && (
          <MDTypography
            variant="body1"
            color="text"
            sx={{
              padding: pxToRem(16),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {translate("dataTable.noResults")}
          </MDTypography>
        )
      )}
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  hasSorting: true,
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
