export enum FilterField {
  SEARCH = "SEARCH",
  SELECT = "SELECT",
  DATE_INTERVAL = "DATE_INTERVAL",
}

export type TableColumn = {
  accessor: string;
  Header: string;
  type?: FilterField;
  valueOptions?: string[];
};

export type FiltersData = {
  [key: string]: any;
};

export type SortingModel = {
  field: string;
  direction: "ASC" | "DESC";
};
