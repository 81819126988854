import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`md-tabpanel-${index}`}
      aria-labelledby={`md-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface MDTabsProps {
  tabs: { label: string; onClick: () => void; content: React.ReactNode }[];
  activeTab: number;
  onTabChange: (newValue: number) => void;
}

export default function MDTabs({ tabs, activeTab, onTabChange }: MDTabsProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box pt={2} sx={{ width: "100%" }}>
      <Box sx={{ width: "50%", textAlign: "center", margin: "0 auto" }}>
        <Tabs
          indicatorColor="secondary"
          value={activeTab}
          onChange={handleChange}
          aria-label="md-tabs"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} onClick={tab.onClick} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={activeTab} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
