import { format } from "date-fns";
import { ro } from "date-fns/locale";
import { CNP_REGEX } from "./regexLogic";

export function getDifference<T>(array1: T[], array2: T[], key: keyof T) {
  if (!Array.isArray(array2)) {
    return [...array1];
  }

  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[key] === object2[key];
    });
  });
}

export function formatDateWithLocale(
  date: string,
  dateFormat?: string,
  locale?: Locale
) {
  return format(new Date(date), dateFormat || "dd MMM yyyy", {
    locale: locale || ro,
  });
}

export function getToken() {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
}

export function validateCNP(value: string): boolean {
  if (!value) {
    return true;
  }

  const control = "279146358279";
  let bigSum = 0;
  let ctrlDigit = 0;

  if (value.match(CNP_REGEX)) {
    for (let i = 0; i < 12; i++) {
      bigSum += parseInt(value[i]) * parseInt(control[i]);
    }
    ctrlDigit = bigSum % 11;
    if (ctrlDigit === 10) {
      ctrlDigit = 1;
    }

    return ctrlDigit === parseInt(value[12]);
  }

  return false;
}

export function decimalFormatter(value: number, decimals: number): string {
  return (Math.round(value * 100) / 100).toFixed(decimals);
}
