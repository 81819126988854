import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";

const MaterialUI = createContext<any>(null);
MaterialUI.displayName = "MaterialUIContext";

// types
interface StateTypes {
  miniSidenav: boolean;
  layout: "dashboard" | "page";
  darkMode: boolean;
}

interface ActionTypes {
  type: "MINI_SIDENAV" | "LAYOUT" | "DARKMODE";
  value: any;
}

function reducer(state: StateTypes, action: ActionTypes) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MaterialUIControllerProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const initialState: StateTypes = {
    miniSidenav: false,
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (
  dispatch: (arg: { type: "MINI_SIDENAV"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "MINI_SIDENAV", value });
const setLayout = (
  dispatch: (arg: { type: "LAYOUT"; value: "dashboard" | "page" }) => void,
  value: "dashboard" | "page"
) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (
  dispatch: (arg: { type: "DARKMODE"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setLayout,
  setDarkMode,
};
