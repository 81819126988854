import IconButton from "@mui/material/IconButton";
import { Edit, Visibility } from "@mui/icons-material";
import { NavigateFunction } from "react-router-dom";

import { EAdminStatus, IUser } from "interfaces";
import MDBox from "../components/mui/MDBox";
import DeleteUser from "features/Users/UserDetails/components/DeleteUser";

export const usersTableColumns = [
  { Header: "Name", accessor: "name" },
  { Header: "Email", accessor: "email" },
  { Header: "Rol", accessor: "role.name" },
  { Header: "Status", accessor: "status" },
  { Header: "Actiuni", accessor: "actions", width: "10%" },
];

export const usersChangelogTableColumns = [
  { Header: "Date", accessor: "createdAt", width: "10%" },
  { Header: "Log", accessor: "type" },
];

export const renderActions = (
  row: IUser,
  navigate: NavigateFunction,
  refreshUsersList: (users: IUser[]) => void
) => {
  const isInactive = row.status === EAdminStatus.INACTIVE;

  return (
    <MDBox style={{ display: "flex" }}>
      <IconButton
        onClick={() => {
          navigate(`/users/userdetails/${row.id}`);
        }}
      >
        <Visibility />
      </IconButton>
      {!isInactive && (
        <>
          <IconButton
            onClick={() => {
              navigate(`/users/userdetails/${row.id}`, {
                state: { edit: true },
              });
            }}
          >
            <Edit />
          </IconButton>
          <DeleteUser row={row} refreshUsersList={refreshUsersList} />
        </>
      )}
    </MDBox>
  );
};
