const keysToCheck = [
  "status",
  "petitionType",
  "petitionObject",
  "urgency",
  "petitionerType",
  "solicitant",
  "insuranceClass",
  "paymentType",
  "gdprChannel",
];

export const replaceUnderscoresWithSpaces = (object: {
  [key: string]: string;
}) => {
  for (const key in object) {
    if (object[key] && keysToCheck.includes(key)) {
      object[key] = object[key].replace(/_/g, " ");
    }
  }
};
