import { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Clear, Download } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import MDDropdown from "components/mui/MDDropdown";
import MDInput from "components/mui/MDInput";
import { translate } from "translations/i18n";
import { FilterField, FiltersData } from "types/dataTableTypes";

dayjs.extend(utc); // need this for timezone="UTC"

interface Props {
  filteringFields: FiltersData[];
  canSearch?: boolean;
  hasExportCSV?: boolean;
  onFilterChange?: (filters: FiltersData) => void;
  handleExportCSV?: (filters: FiltersData) => void;
}

const FiltersWrapper = (props: Props): JSX.Element => {
  const {
    filteringFields,
    canSearch,
    hasExportCSV,
    onFilterChange,
    handleExportCSV,
  } = props;

  const [filters, setFilters] = useState<FiltersData>({});

  useEffect(() => {
    onFilterChange(filters);
  }, [filters]);

  const handleSearchChange = (value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchTerm: value.trim() || undefined,
    }));
  };

  const handleFilterChange = (filterKey: string, newValue: string | any) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterKey]: newValue }));
  };

  // RESET FILTERS BUTTONS
  const handleClearFilter = (filterKey: string) => {
    setFilters((prevFilters) => {
      const { [filterKey]: _, ...remainingFilter } = prevFilters;

      return remainingFilter;
    });
  };

  const handleClearAllFilters = () => {
    setFilters({});
  };

  const renderFilterClearButton = (filterKey: string) => {
    if (filters[filterKey]) {
      return (
        <IconButton
          onClick={() => handleClearFilter(filterKey)}
          size="small"
          aria-label={`Clear ${filterKey} filter`}
        >
          <Clear />
        </IconButton>
      );
    }
    return null;
  };

  const renderSearchInput = () => {
    return (
      <Grid item xs={12} sm={4} md={3} lg={2} p={0.5}>
        <MDInput
          placeholder={`${translate("dataTable.search")}...`}
          size="medium"
          fullWidth
          value={filters["searchTerm"] || ""}
          onChange={({ currentTarget }: any) => {
            handleSearchChange(currentTarget.value);
          }}
        />
      </Grid>
    );
  };

  const renderFilterComponent = (
    column: FiltersData,
    index: number,
    value?: any
  ) => {
    switch (column.type) {
      case FilterField.SELECT:
        return (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            p={0.5}
            key={index}
            display="flex"
          >
            <MDDropdown
              key={index}
              name={column.name}
              label={column.Header}
              options={column.valueOptions}
              multiselect={column.multiselect}
              value={filters[column.name] || null}
              onChange={(newValue) => {
                handleFilterChange(column.name, newValue.target.value);
              }}
            />
            {renderFilterClearButton(column.name)}
          </Grid>
        );
      case FilterField.DATE_INTERVAL:
        return (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            p={0.5}
            key={index}
            display="flex"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                calendars={1}
                onChange={(newValue: DateRange<Date>) => {
                  handleFilterChange(column.name, newValue);
                }}
                value={filters[column.name] || [null, null]}
                format="DD.MM.YY"
                timezone="UTC"
                slotProps={{
                  textField: {
                    label: column.Header,
                  },
                }}
              />
            </LocalizationProvider>
            {renderFilterClearButton(column.name)}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {filteringFields && (
        <Grid container flexWrap="wrap" alignItems="end" pl={2} pr={2} py={1}>
          {canSearch && renderSearchInput()}
          {filteringFields.map(renderFilterComponent)}
        </Grid>
      )}
      <Grid display="flex" justifyContent="end">
        {hasExportCSV && (
          <Button startIcon={<Download />} onClick={handleExportCSV}>
            {translate("dataTable.filters.export")}
          </Button>
        )}
        {filteringFields && (
          <Button
            onClick={handleClearAllFilters}
            size="small"
            aria-label="Clear All Filters"
            disabled={Object.keys(filters).length === 0}
          >
            {translate("dataTable.filters.resetFilters")}
          </Button>
        )}
      </Grid>
    </>
  );
};

export default FiltersWrapper;
