import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useState } from "react";
import Card from "@mui/material/Card";

import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/mui/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDTypography from "../../../components/mui/MDTypography";
import { translate } from "../../../translations/i18n";
import { Endpoints, postMediaRequest } from "../../../helpers/api";
import MDButton from "../../../components/mui/MDButton";
import PetitionForm from "./components/PetitionForm";
import { IPetition } from "interfaces/petitions";
import { EPetitionSolicitant, EPetitionType } from "types/petitionTypes";
import {
  resetHourDate,
  setMinInternalDeadline,
} from "helpers/petitionsHelpers";
import { newPetitionValidationSchema } from "helpers/validationSchemas";

const processPetitionData = (values: IPetition) => {
  const {
    asfRegistrationDate,
    asfNumber,
    originalPetitionId,
    petitionType,
    ...otherValues
  } = values;

  const isThirdParty =
    otherValues.solicitant === EPetitionSolicitant.THIRD_PARTY;
  const isIdenticalPetition = petitionType === EPetitionType.DUPLICATE;

  return {
    ...otherValues,
    petitionerIdentificationNumber:
      values.petitionerIdentificationNumber.toString(),
    ...(isThirdParty && { asfRegistrationDate, asfNumber }),
    ...(isIdenticalPetition && { originalPetitionId, petitionType }),
  };
};

const AddPetitions = () => {
  const [resetDropzone, setResetDropzone] = useState(false);
  const [resetSearchAgent, setResetSearchAgent] = useState(false);

  const handleSubmit = () => {
    formik.validateForm().then((validationErrors) => {
      console.log(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        const petitionData = processPetitionData(formik.values);

        postMediaRequest(Endpoints.PETITIONS.LIST, petitionData)
          .then(() => {
            toast.success(translate("toastrMessages.successAddNewPetition"));
            formik.resetForm();
            handleDropzoneReset();
            setResetSearchAgent(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // Mark all fields as touched to display the errors
        formik.setTouched(
          Object.keys(formik.values).reduce(
            (acc: { [key: string]: boolean }, key) => {
              acc[key] = true;
              return acc;
            },
            {}
          )
        );
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      petitionerName: "",
      petitionerPhoneNumber: "",
      petitionerAddress: "",
      solicitant: "",
      petitionerType: "",
      petitionObject: "",
      insuranceClass: "",
      inputChannel: "",
      petitionContent: "",
      urgency: "",
      petitionCountry: "Romania",
      receptionDate: "",
      internalDeadline: resetHourDate(setMinInternalDeadline(new Date())),
      documents: [],
      petitionClientFile: [],
      notaFundamentare: [],
      mesajAsf: [],
      originalPetitionId: null,
      petitionerIdentificationNumber: "",
      petitionerEmail: "",
      policyNumber: "",
      claimNumber: "",
      petitionAgentId: "",
      asfRegistrationDate: "",
      asfNumber: "",
      websitePolicyId: "",
    },
    validationSchema: newPetitionValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const handleDropzoneReset = () => {
    setResetDropzone((prevState) => !prevState);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="left">
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            {translate("newPetition.title")}
          </MDTypography>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          {translate("newPetition.subTitle")}
        </MDTypography>
      </MDBox>
      <Card>
        <PetitionForm
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          setFieldValue={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
          handleOnBlur={formik.handleBlur}
          resetDropzone={resetDropzone}
          resetSearchAgent={resetSearchAgent}
        />
      </Card>
      <MDBox mt={2} textAlign="right">
        <MDButton variant="gradient" color="dark" onClick={handleSubmit}>
          {translate("newPetition.sendPetition")}
        </MDButton>
      </MDBox>
    </DashboardLayout>
  );
};

export default AddPetitions;
