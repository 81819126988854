import { FilterField } from "./dataTableTypes";

export enum EPetitionResolutionEndReason {
  ALTELE = "ALTELE",
  AVARII_PREEXISTENTE = "AVARII PREEXISTENTE",
  CERCETARI_SUPLIMENTARE_IN_CURS = "CERCETARI SUPLIMENTARE IN CURS",
  CLAUZE_EXCLUDERE = "CLAUZE EXCLUDERE",
  CULPA_COMUNA = "CULPA COMUNA",
  DAUNE_NECONSTATATE = "DAUNE NECONSTATATE",
  DOSAR_CERCETARE_PENALA = "DOSAR CERCETARE PENALA",
  DOSAR_INCOMPLET = "DOSAR INCOMPLET",
  DOSAR_PRESCRIS = "DOSAR PRESCRIS",
  EXPERTIZA_TEHNICA = "EXPERTIZA TEHNICA",
  INVESTIGATII_PROPRII = "INVESTIGATII PROPRII",
  LIPSA_CALITATE = "LIPSA CALITATE",
  POLITA_NEVALABILA = "POLITA NEVALABILA",
  RECALCULARE_VALOARE_DESBAGUBIRE_RASCUMPARARE = "RECALCULARE VALOARE DESBAGUBIRE RASCUMPARARE",
  RISCURI_NEACOPERITE = "RISCURI NEACOPERITE",
}

export enum EPetitionResolutionEndMode {
  FAVORABIL = "FAVORABIL",
  NEINTEMEIAT = "NEINTEMEIAT",
}

export enum EPetitionSolicitant {
  DIRECT = "DIRECT",
  THIRD_PARTY = "THIRD_PARTY",
}

export enum EPetitionObject {
  NEPLATA_VALOARE_DESPAGUBIRE_RASCUMPARARE = "NEPLATA VALOARE DESPAGUBIRE RASCUMPARARE",
  POLITE_DUBLATE_NULE_FALSE_REZILIATE = "POLITE DUBLATE NULE FALSE REZILIATE",
  RECALCULARE_VALOARE_DESPAGUBIRE_RASCUMPARARE = "RECALCULARE VALOARE DESPAGUBIRE RASCUMPARARE",
  RECLAMA_NEGATIVA = "RECLAMA NEGATIVA",
  SOLICITARE_INFORMATII = "SOLICITARE INFORMATII",
  CLAUZE_ABUZIVE = "CLAUZE ABUZIVE",
  NERESPECTAREA_CONDITIILOR_CONTRACTUALE = "NERESPECTAREA CONDITIILOR CONTRACTUALE",
  RADIERE_AGENT_ASISTENT = "RADIERE AGENT ASISTENT",
  PLATA_PARTIALA = "PLATA PARTIALA",
  REGRES = "REGRES",
  POLITA_ASIGURARE = "POLITA ASIGURARE",
  ALTELE = "ALTELE",
}

export enum EPetitionerType {
  PAGUBIT = "PAGUBIT",
  ASIGURAT = "ASIGURAT",
  REPREZENTANT_PAGUBIT = "REPREZENTANT PAGUBIT",
  REPREZENTANT_ASIGURAT = "REPREZENTANT ASIGURAT",
  SERVICE = "SERVICE",
  BROKER_AGENT = "BROKER AGENT",
}

export enum EPetitionUrgency {
  URGENT = "URGENT",
  MEDIU = "MEDIU",
  SCAZUT = "SCAZUT",
}

export enum EPetitionStatus {
  RECEPTIONAT = "RECEPTIONAT",
  ALOCAT = "ALOCAT",
  IN_LUCRU = "IN LUCRU",
  SOLUTIONAT = "SOLUTIONAT",
}
export enum EPetitionSource {
  FORMULAR_ONLINE = "FORMULAR ONLINE",
  EMAIL = "EMAIL",
  TELEFON = "TELEFON",
  SCRISOARE_RECOMANDATA = "SCRISOARE RECOMANDATA",
}

export enum EPetitionInsuranceClass {
  ACCIDENTE = "ACCIDENTE",
  SANATATE = "SANATATE",
  VEHICULE_TERESTRE = "VEHICULE TERESTRE",
  NAVE_MARITIME = "NAVE MARITIME",
  BUNURI_TRANZIT = "BUNURI TRANZIT",
  INCENDIU_CALAMITATI_NATURALE = "INCENDIU CALAMITATI NATURALE",
  ALTE_DAUNE = "ALTE DAUNE",
  CREDIT = "CREDIT",
  GARANTII = "GARANTII",
  PIERDERI_FINANCIARE = "PIERDERI FINANCIARE",
}

export enum EPetitionType {
  UNIQUE = "UNIQUE",
  DUPLICATE = "DUPLICATE",
}

export type Petition = {
  id: number | null;
  claimNumber: string | null;
  createdAt: string;
  deletedAt: string | null;
  details: string;
  institution: string | null;
  insuranceClass: EPetitionInsuranceClass;
  petitionAgentId: number;
  petitionId: number | null;
  petitionObject: EPetitionObject;
  petitionerAddress: string | null;
  petitionerEmail: string | null;
  petitionerIdentificationNumber: string;
  petitionerName: string;
  petitionerPhoneNumber: string;
  petitionerContact: string | null;
  petitionerType: EPetitionerType;
  policyNumber: number | null;
  receptionDate: string;
  resolutionDate: string;
  solicitant: EPetitionSolicitant;
  status: EPetitionStatus;
  urgency: EPetitionUrgency;
  inputChannel: EPetitionSource;
  petitionContent: string;
  petitionCountry: string;
  originalPetitionId: number | null;
  asfRegistrationDate: string;
  asfNumber: number | null;
};
export enum EPetitionResolveStatus {
  FINALIZATA = "FINALIZATA",
  IN_CURS_DE_SOLUTIONARE = "IN CURS DE SOLUTIONARE",
}

export const petitionTableColumns = [
  {
    accessor: "petitionId",
    Header: "Nr.",
  },
  {
    accessor: "receptionDate",
    Header: "Data Petitiei",
  },
  {
    accessor: "status",
    Header: "Status",
  },
  {
    accessor: "petitionType",
    Header: "Tip",
  },
  {
    accessor: "originalPetitionId",
    Header: "Nr petitie identica",
  },
  {
    accessor: "petitionAgentId",
    Header: "Responsabil",
    disableSortBy: true,
  },
  {
    accessor: "petitionerName",
    Header: "Nume petent",
  },
  {
    accessor: "petitionerType",
    Header: "Calitate petent",
  },
  {
    accessor: "policyNumber",
    Header: "Nr. polita",
    disableSortBy: true,
  },
  {
    accessor: "claimNumber",
    Header: "Nr. dosar dauna",
    disableSortBy: true,
  },
  {
    accessor: "petitionObject",
    Header: "Obiectul petitiei",
  },
  {
    accessor: "urgency",
    Header: "Prioritate",
  },
  { Header: "Actiuni", accessor: "actions", width: "10%", disableSortBy: true },
];

export const petitionReportsTableColumns = [
  {
    accessor: "id",
    Header: "Nr.",
  },
  {
    accessor: "createdAt",
    Header: "Data creare raport",
  },
  {
    accessor: "type",
    Header: "Tip",
  },
  {
    accessor: "name",
    Header: "Nume",
  },
  {
    Header: "Descarca",
    accessor: "actions",
    width: "10%",
    disableSortBy: true,
  },
];

export const petitionFilters = [
  {
    name: "searchTerm",
    Header: "Nr.",
    type: FilterField.SEARCH,
  },
  {
    name: "status",
    Header: "Status",
    type: FilterField.SELECT,
    valueOptions: EPetitionStatus,
  },
  {
    name: "petitionType",
    Header: "Tip",
    type: FilterField.SELECT,
    valueOptions: EPetitionType,
  },
  {
    name: "petitionAgentId",
    Header: "Responsabil",
    type: FilterField.SELECT,
  },
  {
    name: "petitionerType",
    Header: "Calitate petent",
    type: FilterField.SELECT,
    valueOptions: EPetitionerType,
  },
  {
    name: "petitionObject",
    Header: "Obiectul petitiei",
    type: FilterField.SELECT,
    valueOptions: EPetitionObject,
  },
  {
    name: "urgency",
    Header: "Prioritate",
    type: FilterField.SELECT,
    valueOptions: EPetitionUrgency,
  },
  {
    name: "receptionDate",
    Header: "Data Petitiei",
    type: FilterField.DATE_INTERVAL,
  },
];

export const petitionRegistryTableColumns = [
  { Header: "Numarul petitiei", accessor: "petitionNumber" },
  { Header: "Data petitiei", accessor: "receptionDate" },
  { Header: "Nume petent", accessor: "petitionerName" },
  {
    Header: "Adresa/telefon/email",
    accessor: "petitionerContact",
  },
  { Header: "Numarul dosarului", accessor: "claimNumber" },
  { Header: "Nr. polita", accessor: "policyNumber" },
  {
    Header: "Obiectul petitiei",
    accessor: "petitionObject",
    style: { width: 210 },
  },
  {
    Header: "Solicitant",
    accessor: "solicitant",
  },
  { Header: "Nr. ASF", accessor: "asfNumber" },
  { Header: "Data petitiei ASF", accessor: "asfReceptionDate" },
  {
    Header: "Observatii",
    accessor: "observations",
    style: { width: 250 },
  },
];

export enum EPetitionObjectUnderscore {
  NEPLATA_VALOARE_DESPAGUBIRE_RASCUMPARARE = "NEPLATA_VALOARE_DESPAGUBIRE_RASCUMPARARE",
  POLITE_DUBLATE_NULE_FALSE_REZILIATE = "POLITE_DUBLATE_NULE_FALSE_REZILIATE",
  RECALCULARE_VALOARE_DESPAGUBIRE_RASCUMPARARE = "RECALCULARE_VALOARE_DESPAGUBIRE_RASCUMPARARE",
  RECLAMA_NEGATIVA = "RECLAMA_NEGATIVA",
  SOLICITARE_INFORMATII = "SOLICITARE_INFORMATII",
  CLAUZE_ABUZIVE = "CLAUZE_ABUZIVE",
  NERESPECTAREA_CONDITIILOR_CONTRACTUALE = "NERESPECTAREA_CONDITIILOR_CONTRACTUALE",
  RADIERE_AGENT_ASISTENT = "RADIERE_AGENT_ASISTENT",
  PLATA_PARTIALA = "PLATA_PARTIALA",
  REGRES = "REGRES",
  POLITA_ASIGURARE = "POLITA_ASIGURARE",
  ALTELE = "ALTELE",
}

export enum EPetitionerTypeUnderscore {
  PAGUBIT = "PAGUBIT",
  ASIGURAT = "ASIGURAT",
  REPREZENTANT_PAGUBIT = "REPREZENTANT_PAGUBIT",
  REPREZENTANT_ASIGURAT = "REPREZENTANT_ASIGURAT",
  SERVICE = "SERVICE",
  BROKER_AGENT = "BROKER_AGENT",
  NU_STIU = "NU_STIU",
}

export enum EPetitionStatusUnderscore {
  RECEPTIONAT = "RECEPTIONAT",
  ALOCAT = "ALOCAT",
  IN_LUCRU = "IN_LUCRU",
  SOLUTIONAT = "SOLUTIONAT",
  RESPINS = "RESPINS",
  INCHIS = "INCHIS",
}

export enum EPetitionSourceUnderscore {
  FORMULAR_ONLINE = "FORMULAR_ONLINE",
  EMAIL = "EMAIL",
  TELEFON = "TELEFON",
  SCRISOARE_RECOMANDATA = "SCRISOARE_RECOMANDATA",
}

export enum EPetitionInsuranceClassUnderscore {
  ACCIDENTE = "ACCIDENTE",
  SANATATE = "SANATATE",
  VEHICULE_TERESTRE = "VEHICULE_TERESTRE",
  NAVE_MARITIME = "NAVE_MARITIME",
  BUNURI_TRANZIT = "BUNURI_TRANZIT",
  INCENDIU_CALAMITATI_NATURALE = "INCENDIU_CALAMITATI_NATURALE",
  ALTE_DAUNE = "ALTE_DAUNE",
  CREDIT = "CREDIT",
  GARANTII = "GARANTII",
  PIERDERI_FINANCIARE = "PIERDERI_FINANCIARE",
}

export enum EPetitionResolutionEndReasonUnderscore {
  ALTELE = "ALTELE",
  AVARII_PREEXISTENTE = "AVARII_PREEXISTENTE",
  CERCETARI_SUPLIMENTARE_IN_CURS = "CERCETARI_SUPLIMENTARE_IN_CURS",
  CLAUZE_EXCLUDERE = "CLAUZE_EXCLUDERE",
  CULPA_COMUNA = "CULPA_COMUNA",
  DAUNE_NECONSTATATE = "DAUNE_NECONSTATATE",
  DOSAR_CERCETARE_PENALA = "DOSAR_CERCETARE_PENALA",
  DOSAR_INCOMPLET = "DOSAR_INCOMPLET",
  DOSAR_PRESCRIS = "DOSAR_PRESCRIS",
  EXPERTIZA_TEHNICA = "EXPERTIZA_TEHNICA",
  INVESTIGATII_PROPRII = "INVESTIGATII_PROPRII",
  LIPSA_CALITATE = "LIPSA_CALITATE",
  POLITA_NEVALABILA = "POLITA_NEVALABILA",
  RECALCULARE_VALOARE_DESBAGUBIRE_RASCUMPARARE = "RECALCULARE_VALOARE_DESBAGUBIRE_RASCUMPARARE",
  RISCURI_NEACOPERITE = "RISCURI_NEACOPERITE",
}
