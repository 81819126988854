import React, { useState } from 'react';

const PetitionClientResponse = () => {
  const [response, setResponse] = useState('');

  const handleResponseChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResponse(event.target.value);
  };

  const handleSendResponse = () => {
    // Here, you can implement the logic to send the response
    console.log('Sending response:', response);
    // You can reset the response state after sending if needed
    setResponse('');
  };

  return (
    <div>
      <h2>Raspunsul catre Client</h2>
      {/* Display previous client responses */}
      {/* WYSIWYG Editor for the operator to write responses */}
      <textarea value={response} onChange={handleResponseChange} />
      {/* Button to send the response */}
      <button onClick={handleSendResponse}>Send Response</button>
    </div>
  );
};

export default PetitionClientResponse;