import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { FormikErrors } from "formik";

import MDTypography from "../../../../../components/mui/MDTypography";
import MDBox from "../../../../../components/mui/MDBox";
import { translate } from "translations/i18n";
import { INewUserFormValues } from "interfaces";

interface Props {
  handleChange: (e: ChangeEvent) => void;
  values: INewUserFormValues;
  errors: FormikErrors<INewUserFormValues>;
}

const UserDetails = (props: Props) => {
  const { handleChange, values, errors } = props;

  return (
    <MDBox>
      <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            {translate("users.userDetailsDescription")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={4}>
            <Grid item>
              <TextField
                name="name"
                variant="outlined"
                label={translate("users.nameInputLabel")}
                value={values.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                fullWidth
              />
            </Grid>
            <Grid item mt={2}>
              <TextField
                name="email"
                variant="outlined"
                label={translate("users.emailInputLabel")}
                value={values.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                type={"email"}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default UserDetails;
