import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  Download as DownloadIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Article as ArticleIcon,
  Image as ImageIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { translate } from "../../../translations/i18n";
import { EFileTypes } from "../../../types/documentType";
import { ICommonDocumentProperties } from "../../../interfaces/petitions";

type DocumentListItemProps = {
  documents: ICommonDocumentProperties[];
  handleDownloadFile?: (documentId: string, fileName: string) => void;
};

const MDDocumentListItem: React.FC<DocumentListItemProps> = ({
  documents,
  handleDownloadFile,
}) => {
  const getIconForExtension = (extension: string) => {
    switch (extension) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "txt":
      case "doc":
      case "docx":
        return <ArticleIcon />;
      case "jpg":
      case "jpeg":
      case "png":
        return <ImageIcon />;
      default:
        return <AttachFileIcon />;
    }
  };

  const getLabelForType = (type: string) => {
    switch (type) {
      case EFileTypes.NOTA_FUNDAMENTARE:
        return "Fisier Nota fundamentare";
      case EFileTypes.PETITION_CLIENT_FILE:
        return "Fisierul clientului";
      case EFileTypes.MESAJ_ASF:
        return "Fisier Mesaj ASF";
      default:
        return type; // Return the type as is if it doesn't match the known types
    }
  };

  return (
    <MDBox mt={1} textAlign="left">
      <MDTypography variant="h5" fontWeight="bold">
        {translate("petitionDetails.documentsSectionTitle")}
      </MDTypography>
      {documents.length === 0 ? (
        <p>Nu exista documente</p>
      ) : (
        <List>
          {documents.map((document, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() =>
                    handleDownloadFile &&
                    handleDownloadFile(document.id.toString(), document.name)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{getIconForExtension(document.extension)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={document.name}
                secondary={getLabelForType(document.type)}
              />
            </ListItem>
          ))}
        </List>
      )}
    </MDBox>
  );
};

export default MDDocumentListItem;
