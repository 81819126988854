import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDTypography from "components/mui/MDTypography";
import { translate } from "translations/i18n";
import MDBox from "components/mui/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDButton from "components/mui/MDButton";
import RoleDetails from "./components/RoleDetails";
import Permissions from "./components/Permissions";
import Review from "./components/Review";
import { Endpoints, postRequest } from "helpers/api";

const validationSchema = yup.object({
  roleName: yup.string().required(),
});

const steps = [
  translate("roles.roleInfoStepTitle"),
  translate("roles.roleSelectPermissions"),
  translate("roles.roleAddReview"),
];

const AddRole = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleSubmit = () => {
    postRequest(Endpoints.ROLES, {
      name: formik.values.roleName,
      active: false,
      permissions: formik.values.permissions,
    })
      .then(() => {
        toast.success(translate("toastrMessages.successAddNewRole"));
        navigate("/roles/roleslist");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      roleName: "",
      permissions: [],
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const getStepContent = (stepIndex: number): JSX.Element => {
    switch (stepIndex) {
      case 0:
        return (
          <RoleDetails
            handleChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
          />
        );
      case 1:
        return (
          <Permissions
            handleChange={(newPermissions) => {
              formik.setFieldValue("permissions", newPermissions);
            }}
            values={formik.values}
          />
        );
      case 2:
        return <Review values={formik.values} />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {translate("roles.addRoleTitle")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                {translate("roles.addRoleDescription")}
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel>
                        <>{label}</>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                      >
                        {translate("roles.back")}
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      disabled={!formik.isValid}
                      onClick={!isLastStep ? handleNext : formik.submitForm}
                    >
                      <>
                        {isLastStep
                          ? translate("roles.add")
                          : translate("roles.next")}
                      </>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AddRole;
