import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AuthCode from "react-auth-code-input";

interface Props {
  onChange: (code: string) => void;
}

const OTPWrapper = styled(Box)(() => {
  return {
    textAlign: "center",
    "& > div": {
      display: "flex",
      justifyContent: "center",
    },
    "& input": {
      width: 45,
      height: 45,
      padding: 0,
      fontSize: 24,
      textAlign: "center",
      marginRight: 12,
      textTransform: "uppercase",
      color: "#494949",
      fontFamily:
        "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
      border: "1px solid #d6d6d6",
      borderRadius: 4,
      background: "#fff",
      backgroundClip: "padding-box",
      "&:last-child": {
        marginRight: 0,
      },
      "&:focus": {
        appearance: "none",
        outline: 0,
        boxShadow: "0 0 0 3px rgb(131 192 253 / 50%)",
      },
    },
  };
});

const OTPCodeInput = (props: Props) => {
  return (
    <OTPWrapper>
      <AuthCode onChange={props.onChange} />
    </OTPWrapper>
  );
};

export default OTPCodeInput;
