import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { FormikErrors } from "formik";

import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { translate } from "translations/i18n";
import { INewRoleFormValues } from "interfaces";

interface Props {
  handleChange: (e: ChangeEvent) => void;
  values: INewRoleFormValues;
  errors: FormikErrors<INewRoleFormValues>;
}

const RoleDetails = (props: Props) => {
  const { handleChange, values, errors } = props;

  return (
    <MDBox>
      <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            {translate("roles.roleInfoStepTitle")}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {translate("roles.roleInfoStepHelpDescription")}
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={4}>
            <TextField
              name="roleName"
              variant="outlined"
              label={translate("roles.roleInputLabel")}
              value={values.roleName}
              onChange={handleChange}
              error={Boolean(errors.roleName)}
              helperText={errors.roleName}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default RoleDetails;
