import { FiltersData } from "types/dataTableTypes";

export const renameKeys = (
  filters: FiltersData,
  keyMappings: { [key: string]: string }
) => {
  return Object.keys(filters).reduce(
    (result: { [key: string]: string }, key) => {
      const newKey = keyMappings[key] || key;
      const value = filters[key];

      if (value !== undefined && value !== null) {
        result[newKey] = value;
      }
      return result;
    },
    {}
  );
};
