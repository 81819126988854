import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
    </DashboardLayout>
  );
};

export default Dashboard;
