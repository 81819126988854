import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Grid,
  Divider,
  SelectChangeEvent,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Edit, Help } from "@mui/icons-material";
import { toast } from "react-toastify";
import { get } from "lodash";

import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDDropdown from "components/mui/MDDropdown";
import MDDatePicker from "components/mui/MDDatePicker";
import { translate } from "translations/i18n";
import { IPetition, IPetitionUpdate } from "interfaces/petitions";
import {
  EPetitionResolutionEndMode,
  EPetitionResolutionEndReason,
  EPetitionResolveStatus,
  EPetitionStatus,
  EPetitionType,
} from "types/petitionTypes";
import { Endpoints, patchRequestMultipart } from "helpers/api";
import MDEditor from "components/mui/MDEditor";
import MDDropzone from "components/mui/MDDropzone";
import MDDocumentListItem from "components/mui/MDDocumentListIrem";
import {
  formatDate,
  mapStatusToResolveStatus,
  petitionOptions,
} from "helpers/petitionsHelpers";
import MDSearchDuplicatePetitionId from "components/mui/MDSearchDuplicatePetitionId";
import { updatePetitionValidationSchema } from "helpers/validationSchemas";
import { downloadFile } from "services/cardPaymentsServices";
import AgentSearch from "components/mui/MDGetAgents";
import { useNavigate } from "react-router-dom";

type PetitionUpdatesProps = {
  petitionData: IPetition;
  petitionUpdate?: IPetitionUpdate;
};

const PetitionUpdates: React.FC<PetitionUpdatesProps> = ({ petitionData }) => {
  const propsPetitionData = petitionData;
  const [resetDropzone, setResetDropzone] = useState(false);
  const [isFieldsEnabled, setIsFieldsEnabled] = useState(false);
  const [isResolutionEndReasonDisabled, setIsResolutionEndReasonDisabled] =
    useState(true);
  const [showResolutionEndReason, setShowResolutionEndReason] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    const id = propsPetitionData.id;

    const processedData = { ...formik.values };

    processedData.petitionResolutionEndReason =
      processedData.petitionResolutionEndMode ===
      EPetitionResolutionEndMode.FAVORABIL
        ? "null"
        : processedData.petitionResolutionEndReason;
    if (
      processedData.petitionResolveStatus ===
      EPetitionResolveStatus.IN_CURS_DE_SOLUTIONARE
    ) {
      processedData.petitionResolutionEndReason = "null";
      processedData.petitionResolutionEndMode = "null";
      processedData.resolutionDate = "null";
      processedData.internalDeadline = "null";
      delete processedData.internalDeadline;
    }

    delete processedData.petitionResolveStatus;

    processedData.originalPetitionId =
      processedData.petitionType === EPetitionType.UNIQUE
        ? undefined
        : processedData.originalPetitionId;

    processedData.observations =
      processedData.petitionType === EPetitionType.UNIQUE
        ? (processedData.observations = "null")
        : processedData.observations;

    patchRequestMultipart(
      `${Endpoints.PETITIONS.PETITION_BY_ID(id)}`,
      processedData
    )
      .then(() => {
        toast.success(translate("toastrMessages.successUpdatePetition"));
        setResetDropzone(true);
        setTimeout(() => {
          setResetDropzone(false);
        }, 500);
        setIsFieldsEnabled(false);
        setIsResolutionEndReasonDisabled(true);
        navigate("/petitions/petitionsList");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      resolutionDate: propsPetitionData.resolutionDate || "",
      updateNotes: propsPetitionData.updateNotes || "",
      status: propsPetitionData.status,
      petitionType: propsPetitionData.petitionType || "",
      originalPetitionId: propsPetitionData.originalPetitionId || null,
      petitionClientFile: [],
      notaFundamentare: [],
      mesajAsf: [],
      petitionResolutionEndMode: propsPetitionData.petitionResolutionEndMode,
      petitionResolutionEndReason:
        propsPetitionData.petitionResolutionEndReason,
      petitionResolveStatus: mapStatusToResolveStatus(propsPetitionData.status),
      internalDeadline: propsPetitionData.internalDeadline,
      observations: propsPetitionData.observations || "",
      petitionAgentId: +propsPetitionData.petitionAgentId,
      asfNumber: propsPetitionData.asfNumber,
      asfRegistrationDate: propsPetitionData.asfRegistrationDate,
      registryNumberIO: propsPetitionData.registryNumberIO,
    },
    validationSchema: updatePetitionValidationSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (
      formik.values.petitionResolveStatus === EPetitionResolveStatus.FINALIZATA
    ) {
      setIsFieldsEnabled(true);
      setShowResolutionEndReason(false);
    } else {
      formik.setFieldValue("petitionResolutionEndReason", "");
      formik.setFieldValue("petitionResolutionEndMode", "");
      formik.setFieldValue("resolutionDate", "");
      setIsResolutionEndReasonDisabled(true);
      setIsFieldsEnabled(false);
      setShowResolutionEndReason(false);
    }
  }, [formik.values.petitionResolveStatus]);

  useEffect(() => {
    if (
      formik.values.petitionResolutionEndMode ===
      EPetitionResolutionEndMode.NEINTEMEIAT
    ) {
      setIsResolutionEndReasonDisabled(false);
      setShowResolutionEndReason(true);
    } else {
      setShowResolutionEndReason(false);
    }
  }, [formik.values.petitionResolutionEndMode]);

  const handleAgentChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    selectedOption
      ? setFieldValue("petitionAgentId", selectedOption.value)
      : null;
  };

  const handleEditorChange = (content: string) => {
    const syntheticEvent = {
      target: {
        name: "updateNotes",
        value: content,
      },
    } as ChangeEvent<HTMLInputElement>;
    formik.handleChange(syntheticEvent);
  };

  const handleAutocompleteChange = (selectedOption: {
    value: number;
    label: string;
    receptionDate: string;
  }) => {
    if (selectedOption) {
      formik.setFieldValue("originalPetitionId", selectedOption.value);
      formik.setFieldValue(
        "observations",
        selectedOption.receptionDate !== null
          ? `Nr. ${selectedOption.value} din ${formatDate(
              propsPetitionData.receptionDate
            )}`
          : "null"
      );
    }
  };

  const handleDownloadFile = (documentId: string, fileName: string) => {
    const url = `${Endpoints.PETITIONS.DOWNLOAD_PETITION_DOCUMENTS(
      propsPetitionData.petitionDocumentId,
      documentId
    )}`;
    downloadFile(url, fileName);
  };

  const hasError = (name: string) => {
    return !!(get(formik.touched, name) && get(formik.errors, name));
  };

  const displayHelperText = (name: string) => {
    return hasError(name) ? get(formik.errors, name) : "";
  };

  const handlePetitionStatus = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = event.target;
    if (name === "status") {
      const newValue = value === "IN LUCRU" ? "IN_LUCRU" : value;
      formik.setFieldValue("status", newValue || null);
      formik.setFieldValue(
        "petitionResolveStatus",
        mapStatusToResolveStatus(newValue) || null
      );
      if (
        mapStatusToResolveStatus(newValue) === EPetitionResolveStatus.FINALIZATA
      ) {
        formik.setFieldValue("resolutionDate", "");
        formik.setFieldValue("petitionResolutionEndReason", "");
        formik.setFieldValue("petitionResolutionEndMode", "");
        setShowResolutionEndReason(false);
        setIsFieldsEnabled(true);
      } else {
        setIsFieldsEnabled(false);
        setShowResolutionEndReason(false);
      }
    }
  };

  const handlePetitionEndModeStatus = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = event.target;

    if (name === "petitionResolutionEndMode") {
      formik.setFieldValue("petitionResolutionEndMode", value || null);
      if (value === EPetitionResolutionEndMode.FAVORABIL || value === "") {
        setIsResolutionEndReasonDisabled(true);
        setShowResolutionEndReason(false);
        formik.setFieldValue("petitionResolutionEndReason", "");
      } else {
        setShowResolutionEndReason(true);
        setIsResolutionEndReasonDisabled(false);
      }
    }
  };

  const handlePetitionTypeChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = event.target;
    if (name === "petitionType") {
      const selectedValue = Array.isArray(value) ? value[0] : value;
      formik.setFieldValue("petitionType", selectedValue || null);

      formik.setFieldValue(
        "observations",
        selectedValue === EPetitionType.UNIQUE
          ? ""
          : propsPetitionData.observations
      );
    }
  };

  return (
    <MDBox>
      {/* Petition Updates */}
      <MDBox p={2}>
        <MDBox mt={1} textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("petitionDetails.petitionUpdates.title")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="status"
            label={translate("petitionDetails.petitionUpdates.petitionStatus")}
            value={propsPetitionData.status}
            options={EPetitionStatus}
            onChange={handlePetitionStatus}
            handleOnBlur={formik.handleBlur}
            error={hasError("status")}
            helperText={displayHelperText("status")}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="petitionType"
            label={translate("newPetition.petitionType")}
            value={propsPetitionData.petitionType}
            options={petitionOptions}
            onChange={handlePetitionTypeChange}
          />
        </Grid>
        {formik.values.petitionType === EPetitionType.DUPLICATE && (
          <Grid item xs={6} sm={8}>
            <MDSearchDuplicatePetitionId
              id={propsPetitionData.originalPetitionId}
              name="originalPetitionId"
              label={translate("newPetition.originalPetitionId")}
              onChange={handleAutocompleteChange}
              error={hasError("originalPetitionId")}
              helperText={displayHelperText("originalPetitionId")}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <AgentSearch
            name="petitionAgentId"
            selectedAgentId={+formik.values.petitionAgentId}
            label={translate("petitionDetails.petitionUpdates.petitionAgentId")}
            onChange={handleAgentChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="registryNumberIO"
            variant="outlined"
            label={translate(
              "petitionDetails.petitionUpdates.registryNumberIO"
            )}
            value={propsPetitionData.registryNumberIO}
            onChange={formik.handleChange}
            fullWidth
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} sm={12}>
          <MDEditor
            name="updateNotes"
            label={translate("petitionDetails.petitionUpdates.updateNotes")}
            value={formik.values.updateNotes}
            onChange={handleEditorChange}
            error={formik.errors.updateNotes}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="petitionResolveStatus"
            label={translate(
              "petitionDetails.petitionUpdates.petitionResolveStatus"
            )}
            value={formik.values.petitionResolveStatus}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4} container alignItems="center">
          <Grid item xs={10}>
            <TextField
              name="observations"
              variant="outlined"
              label={translate("petitionDetails.petitionUpdates.observations")}
              value={formik.values.observations}
              onChange={formik.handleChange}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={translate(
                "petitionDetails.petitionUpdates.observationsInfo"
              )}
            >
              <IconButton>
                <Help />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <MDDatePicker
            name="resolutionDate"
            variant="outlined"
            label={translate("petitionDetails.petitionUpdates.resolutionDate")}
            value={formik.values.resolutionDate}
            handleOnBlur={formik.handleChange}
            error={hasError("resolutionDate")}
            helperText={displayHelperText("resolutionDate")}
            disabled={!isFieldsEnabled}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        <Grid item xs={6} sm={4}>
          <MDDropdown
            name="petitionResolutionEndMode"
            label={translate(
              "petitionDetails.petitionUpdates.petitionResolutionEndMode"
            )}
            value={formik.values.petitionResolutionEndMode}
            handleOnBlur={formik.handleBlur}
            error={hasError("petitionResolutionEndMode")}
            helperText={displayHelperText("petitionResolutionEndMode")}
            options={EPetitionResolutionEndMode}
            onChange={handlePetitionEndModeStatus}
            disabled={!isFieldsEnabled}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          {showResolutionEndReason && (
            <MDDropdown
              name="petitionResolutionEndReason"
              label={translate(
                "petitionDetails.petitionUpdates.petitionResolutionEndReason"
              )}
              value={formik.values.petitionResolutionEndReason}
              options={EPetitionResolutionEndReason}
              handleOnBlur={formik.handleBlur}
              error={hasError("petitionResolutionEndReason")}
              helperText={displayHelperText("petitionResolutionEndReason")}
              onChange={formik.handleChange}
              disabled={isResolutionEndReasonDisabled}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} p={2}>
        <Grid item xs={4} md={4}>
          <MDDocumentListItem
            documents={propsPetitionData.documents}
            handleDownloadFile={handleDownloadFile}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid item xs={12} sm={12} p={2}>
        <MDBox textAlign="left">
          <MDTypography variant="h5" fontWeight="bold">
            {translate("newPetition.uploadDocuments.title")}
          </MDTypography>
        </MDBox>
        <MDBox textAlign="left">
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.petitionClientFileTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) =>
                setFieldValue("petitionClientFile", files)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.notaFundamentareTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) =>
                setFieldValue("notaFundamentare", files)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6" fontWeight="bold">
              {translate("newPetition.uploadDocuments.mesajAsfTitle")}
            </MDTypography>
            <MDDropzone
              reset={resetDropzone}
              onChange={(files: File[]) => setFieldValue("mesajAsf", files)}
            />
          </Grid>
        </MDBox>
      </Grid>
      <MDBox textAlign="right" p={2}>
        <Button
          style={{
            color: "white",
            backgroundColor: "green",
            marginLeft: "5px",
          }}
          sx={{ backgroundColor: "green" }}
          variant="contained"
          color="warning"
          onClick={formik.submitForm}
          startIcon={<Edit />}
        >
          {"Actualizeaza"}
        </Button>
      </MDBox>
    </MDBox>
  );
};

export default PetitionUpdates;
