import { Grid } from "@mui/material";
import { FormikErrors } from "formik";
import { useEffect, useState } from "react";

import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import MDAutocomplete from "components/mui/MDAutocomplete";
import { translate } from "translations/i18n";
import { INewUserFormValues, IRole } from "interfaces";
import { Endpoints, getRequest } from "helpers/api";

interface Props {
  values: INewUserFormValues;
  errors: FormikErrors<INewUserFormValues>;
  setFieldValue: (field: string, value: number | string) => void;
}

const RolesList = (props: Props) => {
  const { values, errors, setFieldValue } = props;

  const [roles, setRoles] = useState<IRole[]>([]);

  useEffect(() => {
    getRequest(Endpoints.ROLES)
      .then((res) => {
        setRoles(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <MDBox>
      <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            {translate("users.addUserRoleDescription")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={4}>
            <MDAutocomplete
              name="role"
              variant="outlined"
              label={translate("roles.roleInputLabel")}
              value={values.role}
              options={roles}
              getOptionLabel={(item) => item.name}
              setFieldValue={setFieldValue}
              error={Boolean(errors.role)}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default RolesList;
