import { useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import MDButton from "components/mui/MDButton";
import { translate } from "translations/i18n";
import { Endpoints, postRequest } from "helpers/api";
import OTPCodeInput from "components/OTPCodeInput";

interface Props {
  rememberUser: boolean;
}

const Code2FA = (props: Props) => {
  const { rememberUser } = props;
  const [result, setResult] = useState<string>();

  const navigate = useNavigate();

  const handleOnChange = (res: string) => {
    setResult(res);
  };

  const verifyTwoFa = async () => {
    postRequest(
      Endpoints.TWO_FA,
      { code: result },
      { Authorization: `Bearer ${localStorage.getItem("tempToken")}` }
    )
      .then((res) => {
        rememberUser
          ? localStorage.setItem("token", res.accessToken)
          : sessionStorage.setItem("token", res.accessToken);
        localStorage.removeItem("tempToken");
        toast.success(translate("toastrMessages.successLogin"));
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box textAlign="center">
      <OTPCodeInput onChange={handleOnChange} />
      <MDButton
        variant="contained"
        color="info"
        size="large"
        fullWidth
        sx={{ marginTop: 4 }}
        onClick={verifyTwoFa}
      >
        {translate("twoFa.buttonLabel")}
      </MDButton>
    </Box>
  );
};

export default Code2FA;
