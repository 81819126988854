import colors from "assets/theme-dark/base/colors";

import pxToRem from "assets/theme-dark/functions/pxToRem";

const { transparent } = colors;

// types
type Types = any;

const select: Types = {
  styleOverrides: {
    select: {
      display: "block",
      alignItems: "center",
      padding: `0 ${pxToRem(12)} !important`,

      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },

      "&.Mui-disabled": {
        cursor: "not-allowed",
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },
  },
};

export default select;
