import { IRole } from "interfaces/role";

export interface IUser {
  id?: number;
  role: IRole;
  createdAt: string;
  lastLoggedIn: string;
  name: string;
  email: string;
  status: string;
}

export interface INewUserFormValues {
  name: string;
  email: string;
  role: IRole;
}

export interface IUserChangelog {
  id: number;
  createdAt: string;
  details: any;
  type: string;
}

export enum EAdminStatus {
  ACCOUNT_CREATED = "ACCOUNT_CREATED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  MFA_RESET = "MFA_RESET",
  MFA_SET = "MFA_SET",
  PASSWORD_RESET = "PASSWORD_RESET",
  PASSWORD_SET = "PASSWORD_SET",
}
