import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { LicenseInfo } from "@mui/x-license-pro";

import Sidenav from "components/Sidenav";
import routes from "routes";
import { setMiniSidenav, useMaterialUIController } from "context";
import { IRoute } from "interfaces";
import { getToken } from "utils/utilsFunctions";

import miniLogo from "assets/images/favicon_128.png";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import "react-toastify/dist/ReactToastify.css";
import { Endpoints, getRequest } from "./helpers/api";
import { Helmet } from "react-helmet";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const PrivateRoute = (): any => {
  const [loading, setLoading] = useState(true);
  const [isValidSession, setIsValidSession] = useState(false);

  getRequest(`${Endpoints.USER_SESSION}`)
    .then(() => {
      setIsValidSession(true);
    })
    .catch(() => {
      toast.error("Session expired");

      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      setIsValidSession(false);
    })
    .finally(() => {
      setLoading(false);
    });

  const loggedIn = getToken();

  return loggedIn ? (
    loading ? (
      <>Loading</>
    ) : isValidSession ? (
      <Outlet />
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

const getRoutes = (allRoutes: IRoute[]): any => {
  return allRoutes.map((route: IRoute) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return route.private ? (
        <Route path={route.route} key={route.key} element={<PrivateRoute />}>
          <Route path={route.route} element={route.component} key={route.key} />
        </Route>
      ) : (
        <Route path={route.route} element={route.component} key={route.key} />
      );
    }

    return null;
  });
};

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { layout, miniSidenav, darkMode } = controller;
  const { pathname } = useLocation();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Setting page scroll to 0 when changing  the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {process.env.NEXT_PUBLIC_NODE_ENV !== "production" && (
        <Helmet>
          <meta name="googlebot" content="noindex,nofollow" />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      )}
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          color="primary"
          brandName="Eazy Insurance"
          brand={miniLogo}
          routes={routes}
        />
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}
