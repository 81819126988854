import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";
import { IHeadersRequest } from "interfaces/apiRequests";

import { getToken } from "utils/utilsFunctions";

export const HOST = process.env.REACT_APP_API;

export const Endpoints = {
  USER_STATUS: "admin-management/admin/status",
  SET_PASSWORD: "admin-management/admin/set-password",
  RESET_PASSWORD_REQUEST: "admin-management/admin/reset-password-request",
  RESET_PASSWORD: "admin-management/admin/reset-password",
  SET_OTP: "admin-management/admin/set-otp",
  LOGIN: "admin-management/auth/login",
  TWO_FA: "admin-management/auth/otp",
  USER_SESSION: "admin-management/auth/session",
  ROLES: "admin-management/roles",
  ADMIN_STATUSES: "admin-management/admin/statuses",
  PERMISSIONS: "admin-management/permissions",
  USER: "admin-management/admin",
  PAYMENT: "payment-management/payment",
  PETITIONS: {
    LIST: "petition-management/petition",
    DUPLICATED: "petition-management/petition/duplicated",
    PETITION_BY_ID: (petitionId: string | number) =>
      `${Endpoints.PETITIONS.LIST}/${petitionId}`,
    REGISTRY: "petition-management/petition/registry",
    AGENTS: "petition-management/petition/agents",
    DOWNLOAD_REGISTRY: "petition-management/petition/registry/download",
    QUARTERLY_REPORTS: `petition-management/petition/quarterly-reports`,
    DOWNLOAD_QUARTERLY_REPORT: (petitionReportId: number) =>
      `${Endpoints.PETITIONS.LIST}/quarterly-report/download/${petitionReportId}`,
    DOWNLOAD_PETITION_DOCUMENTS: (
      petitionDocumentId: string,
      documentId: string
    ) =>
      `${Endpoints.PETITIONS.LIST}/download/${petitionDocumentId}/${documentId}`,
  },
};

export const client = axios.create({
  baseURL: HOST,
});

export const request = (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    if (response.data || response.data === 0 || response.data === "0") {
      // for download file
      if (response.config.headers.responseType === "blob") {
        return response as any;
      } else {
        return response?.data;
      }
    }
    return response as any;
  };

  const onError = (error: AxiosError) => {
    console.error("Request Failed:", error.config);
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    }
    const err = error.response.data as {
      code: string;
      message: string;
      errors?: { [key: string]: string };
    };

    if (err.errors) {
      Object.entries(err.errors).forEach((val) => {
        toast.error(
          `${val[0].charAt(0).toUpperCase() + val[0].slice(1)}: ${
            val[1].charAt(0).toUpperCase() + val[1].slice(1)
          }`
        );
      });
    } else {
      toast.error(err.message);
    }

    return Promise.reject(error.response.data);
  };

  return client({
    ...options,
    headers: {
      Authorization: getToken() && `Bearer ${getToken()}`,
      ...(options.headers ?? {}),
    },
  })
    .then(onSuccess)
    .catch(onError);
};

export const getRequest = (
  url: string,
  params = {},
  customHeaders: IHeadersRequest = {},
  customResponseType?: ResponseType
) => {
  const stringified = queryString.stringify(params);
  const paramsString = Object.keys(params).length ? `?${stringified}` : "";

  return request({
    method: "get",
    url: `${url}${paramsString}`,
    headers: customHeaders,
    responseType: customResponseType,
  });
};

export const postRequest = (
  url: string,
  body: Record<string, any> = {},
  customHeaders: IHeadersRequest = {}
) => {
  return request({
    method: "POST",
    url,
    data: body,
    headers: {
      "Content-Type": "application/json",
      ...customHeaders,
    },
  });
};

export const patchRequest = (
  url: string,
  body: Record<string, any> = {},
  customHeaders: IHeadersRequest = {}
) => {
  return request({
    method: "PATCH",
    url,
    data: body,
    headers: {
      "Content-Type": "application/json",
      ...customHeaders,
    },
  });
};

export const patchRequestMultipart = (
  url: string,
  body: Record<string, any> = {},
  customHeaders: IHeadersRequest = {}
) => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val: string) => {
        formData.append(key, val);
      });
    } else if (value) {
      formData.append(key, value);
    }
  });

  return request({
    method: "PATCH",
    url,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      ...customHeaders,
    },
  });
};

export const postMediaRequest = (
  url: string,
  body: Record<string, any> = {},
  customHeaders: IHeadersRequest = {}
) => {
  const formData = new FormData();
  Object.entries(body).forEach((entry) => {
    const [key, value] = entry;
    if (value || (Array.isArray(value) && value.length > 0)) {
      if (Array.isArray(value)) {
        value.forEach((val: string) => formData.append(key, val));
      } else {
        formData.append(key, value);
      }
    }
  });

  return request({
    method: "POST",
    url,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      ...customHeaders,
    },
  });
};

export const deleteRequest = (
  url: string,
  customHeaders: IHeadersRequest = {}
) => {
  return request({
    method: "DELETE",
    url,
    headers: customHeaders,
  });
};
