import Grid from "@mui/material/Grid";

import MDTypography from "../../../../../components/mui/MDTypography";
import MDBox from "../../../../../components/mui/MDBox";
import { translate } from "translations/i18n";
import { INewUserFormValues } from "interfaces";

interface Props {
  values: INewUserFormValues;
}

const Review = (props: Props) => {
  const { values } = props;

  return (
    <MDBox>
      <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            {translate("roles.roleAddReviewTitle")}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {translate("users.userAddReviewDescription")}
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3} ml={2} mr={2}>
          <Grid item xs={12}>
            <MDTypography variant="h6" fontWeight="regular">
              {translate("users.nameInputLabel")}: {values.name}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6" fontWeight="regular">
              {translate("users.emailInputLabel")}: {values.email}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h6" fontWeight="regular">
              {translate("roles.roleInputLabel")}: {values.role?.name}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Review;
