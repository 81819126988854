import { Card } from "@mui/material";
import { useEffect, useState } from "react";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import { Endpoints, getRequest } from "helpers/api";
import { downloadFile } from "services/cardPaymentsServices";
import { translate } from "translations/i18n";
import { Petition, petitionRegistryTableColumns } from "types/petitionTypes";
import { formatDateWithLocale } from "utils";

const PetitionRegistry = () => {
  const [petitionsRegistry, setPetitionsRegistry] = useState([]);

  useEffect(() => {
    getRequest(`${Endpoints.PETITIONS.REGISTRY}`)
      .then((results) => {
        const updatedResults = results.map((result: Petition) => ({
          ...result,
          petitionerContact: result.petitionerContact
            ? result.petitionerContact.replace(/\//g, " / ")
            : result.petitionerContact,
        }));
        setPetitionsRegistry(updatedResults);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleExportCSV = () => {
    const url = `${Endpoints.PETITIONS.DOWNLOAD_REGISTRY}`;
    const fileTitle = "Registru";
    downloadFile(url, fileTitle);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {translate("petitions.registry.title")}
              </MDTypography>
            </MDBox>
            <DataTable
              hasSorting={false}
              table={{
                columns: petitionRegistryTableColumns,
                rows: petitionsRegistry.map((petition) => ({
                  ...petition,
                  receptionDate:
                    petition.receptionDate &&
                    formatDateWithLocale(petition.receptionDate),
                  asfReceptionDate:
                    petition.asfReceptionDate &&
                    formatDateWithLocale(petition.asfReceptionDate),
                })),
              }}
              hasExportCSV
              handleExportCSV={handleExportCSV}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default PetitionRegistry;
