// types
type Types = any;

const formControl: Types = {
  styleOverrides: {
    root: {
      "&.MuiDataGrid-filterFormValueInput .MuiFormControl-root": {
        width: "100%",
      },
    },
  },
};

export default formControl;
