import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

import MDBox from "components/mui/MDBox";
import MDTypography from "components/mui/MDTypography";
import { translate } from "translations/i18n";

interface Props {
  icon: ReactNode;
  title: string;
  route: string | string[];
  light?: boolean;
  [key: string]: any;
}

function Breadcrumbs({ icon, route, light }: Props): JSX.Element {
  const routes = typeof route === "string" ? route.split("/") : route;

  const isLastItemNumeric = !isNaN(Number(routes[routes.length - 1]));
  const actualRoutes = isLastItemNumeric ? routes.slice(0, -1) : routes;
  const id = isLastItemNumeric ? routes[routes.length - 1] : null;

  return (
    <MDBox mr={{ xs: 0, xl: 1 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {actualRoutes.map((el: string, index: number) => (
          <Link
            key={`/${el}`}
            to={`/${actualRoutes.slice(0, index + 1).join("/")}`}
          >
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {translate(`menu.${el}`)}
            </MDTypography>
          </Link>
        ))}

        {id && (
          <Link to={`/${actualRoutes.join("/")}/${id}`}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {id}
            </MDTypography>
          </Link>
        )}
      </MuiBreadcrumbs>
    </MDBox>
  );
}

Breadcrumbs.defaultProps = {
  light: false,
};

export default Breadcrumbs;
