import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { AddCircle } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DataTable from "../../../components/Tables/DataTable";
import MDTypography from "../../../components/mui/MDTypography";
import MDBox from "../../../components/mui/MDBox";
import { translate } from "../../../translations/i18n";
import { renderActions, usersTableColumns } from "helpers/usersHelpers";
import { IUser } from "interfaces";
import { Endpoints, getRequest } from "helpers/api";

const UsersList = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    getRequest(Endpoints.USER)
      .then((res) => {
        setUsers(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const refreshUsersList = (usersList: IUser[]) => {
    setUsers(usersList);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {translate("users.title")}
              </MDTypography>
              <IconButton
                color="success"
                onClick={() => navigate("/users/adduser")}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            </MDBox>
            <DataTable
              hasSorting={false}
              table={{
                columns: usersTableColumns,
                rows: users.map((user) => ({
                  ...user,
                  actions: renderActions(user, navigate, refreshUsersList),
                })),
              }}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default UsersList;
