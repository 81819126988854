import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Checkbox, FormControlLabel } from "@mui/material";

import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { translate } from "translations/i18n";
import { INewRoleFormValues, IPermission } from "interfaces";
import { Endpoints, getRequest } from "helpers/api";

interface Props {
  handleChange: (newPermissions: IPermission[]) => void;
  values: INewRoleFormValues;
}

const Permissions = (props: Props) => {
  const { handleChange, values } = props;

  const [availablePermissions, setAvailablePermissions] = useState<
    IPermission[]
  >([]);

  useEffect(() => {
    getRequest(Endpoints.PERMISSIONS)
      .then((res) => {
        setAvailablePermissions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <MDBox>
      <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            {translate("roles.roleSelectPermissionsTitle")}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {translate("roles.roleSelectPermissionsDescription")}
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={2} ml={2} mr={2}>
          {availablePermissions.map((permission) => (
            <Grid item xs={4} key={permission.code}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.permissions.includes(permission)}
                    onChange={() => {
                      const newPermissions = values.permissions.includes(
                        permission
                      )
                        ? values.permissions.filter((p) => p !== permission)
                        : [...values.permissions, permission];

                      handleChange(newPermissions);
                    }}
                  />
                }
                label={permission.code}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Permissions;
