import IconButton from "@mui/material/IconButton";
import { Edit, Visibility } from "@mui/icons-material";
import { NavigateFunction } from "react-router-dom";

import { IRole } from "interfaces";
import MDBox from "components/mui/MDBox";

export const rolesTableColumns = [
  { Header: "Nume rol", accessor: "name" },
  { Header: "Creat la", accessor: "createdAt" },
  { Header: "Creat de", accessor: "createdBy" },
  { Header: "Activ", accessor: "isActive", width: "10%" },
  { Header: "Actiuni", accessor: "actions", width: "10%" },
];

export const roleUsersTableColumns = [
  { Header: "Utilizator", accessor: "name" },
  { Header: "Email", accessor: "email" },
];

export const roleChangelogTableColumns = [
  { Header: "Data", accessor: "createdAt", width: "10%" },
  { Header: "Log", accessor: "name" },
  { Header: "Modificat de", accessor: "admin.name", width: "20%" },
];

export const renderActions = (row: IRole, navigate: NavigateFunction) => {
  return (
    <MDBox>
      <IconButton
        onClick={() => {
          navigate(`/roles/roledetails/${row.id}`);
        }}
      >
        <Visibility />
      </IconButton>
      {row.active && (
        <IconButton
          onClick={() => {
            navigate(`/roles/roledetails/${row.id}`, { state: { edit: true } });
          }}
        >
          <Edit />
        </IconButton>
      )}
    </MDBox>
  );
};
