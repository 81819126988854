import { useCallback, useEffect, useState } from "react";
import { Card } from "@mui/material";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { translate } from "../../translations/i18n";
import {
  DEFAULT_PAGINATION_MODEL,
  paymentFilters,
  paymentTableColumns,
} from "types/cardPaymentsTypes";

import { Endpoints, getRequest } from "helpers/api";
import DataTable from "components/Tables/DataTable";
import { downloadFile } from "services/cardPaymentsServices";
import { formatDateWithLocale, decimalFormatter } from "utils/utilsFunctions";
import { FiltersData } from "types/dataTableTypes";
import { renameKeys } from "helpers/filtersHelpers";

const CardPayments = () => {
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  const [cardPayments, setCardPayments] = useState([]);
  const [filterModel, setFilterModel] = useState<FiltersData>({});
  const [sortingModel, setSortingModel] = useState(null); //for when we have sorting
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    getRequest(`${Endpoints.PAYMENT}/filter`, {
      ...{
        itemsPerPage: paginationModel.itemsPerPage,
        page: paginationModel.page,
      },
      ...filterModel,
      ...sortingModel,
    })
      .then((payments) => {
        setCardPayments(payments.records);
        setTotalItems(payments.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [paginationModel, filterModel, sortingModel]);

  const handleFilterChange = useCallback((newFilterModel: FiltersData) => {
    const keyMappings = {
      paymentType: "methods",
      gdprChannel: "channels",
      status: "statuses",
    };
    const filteredFilterModel = renameKeys(newFilterModel, keyMappings);

    setFilterModel(filteredFilterModel);
  }, []);

  const handleExportCSV = () => {
    const url = `${Endpoints.PAYMENT}/download`;
    const fileTitle = "Eazy Payments";
    downloadFile(url, fileTitle, filterModel);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {translate("payments.cardPayments.title")}
              </MDTypography>
            </MDBox>
            <DataTable
              hasSorting={false}
              table={{
                columns: paymentTableColumns,
                rows: cardPayments.map((payment) => ({
                  ...payment,
                  createdAt: formatDateWithLocale(payment.createdAt),
                  updatedAt: formatDateWithLocale(payment.updatedAt),
                  amount: decimalFormatter(payment.amount, 2),
                })),
              }}
              filters={paymentFilters}
              totalItems={totalItems}
              hasExportCSV
              onFiltersChange={handleFilterChange}
              handleExportCSV={handleExportCSV}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default CardPayments;
