import Card from "@mui/material/Card";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import MDTypography from "../../../components/mui/MDTypography";
import { translate } from "translations/i18n";
import MDBox from "../../../components/mui/MDBox";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import MDButton from "../../../components/mui/MDButton";
import UserDetails from "./components/UserDetails";
import RolesList from "./components/RolesList";
import Review from "./components/Review";
import { Endpoints, postRequest } from "helpers/api";

const validationSchema = yup.object({
  name: yup.string().when("step", {
    is: 0,
    then: yup
      .string()
      .required(
        `${translate("users.nameInputLabel")} ${translate(
          "users.validationRequired"
        )}`
      ),
  }),
  email: yup.string().when("step", {
    is: 0,
    then: yup
      .string()
      .email(
        `${translate("users.emailInputLabel")} ${translate(
          "users.validationRequired"
        )}`
      )
      .required(
        `${translate("users.emailInputLabel")} ${translate(
          "users.validationRequired"
        )}`
      ),
  }),
  role: yup.object().when("step", {
    is: 1,
    then: yup
      .object()
      .nullable()
      .test(
        "empty",
        translate("users.roleInputLabel"),
        (value: { [key: string]: string }) => {
          return value?.id !== undefined;
        }
      ),
  }),
});

const steps = [
  translate("users.userInfoStepTitle"),
  translate("users.rolesInfoStepTitle"),
  translate("roles.roleAddReview"),
];

const AddUser = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleSubmit = () => {
    postRequest(Endpoints.USER, {
      name: formik.values.name,
      email: formik.values.email,
      roleId: formik.values.role.id,
    })
      .then(() => {
        toast.success(translate("toastrMessages.successAddNewUser"));
        navigate("/users/userslist");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      role: undefined,
      step: 0,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const getStepContent = (stepIndex: number): JSX.Element => {
    switch (stepIndex) {
      case 0:
        return (
          <UserDetails
            handleChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
          />
        );
      case 1:
        return (
          <RolesList
            values={formik.values}
            errors={formik.errors}
            setFieldValue={formik.setFieldValue}
          />
        );
      case 2:
        return <Review values={formik.values} />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    formik.setFieldValue("step", activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    formik.setFieldValue("step", activeStep - 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {translate("users.addUserTitle")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                {translate("users.addUserDescription")}
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel>
                        <>{label}</>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                      >
                        {translate("roles.back")}
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      disabled={!formik.isValid}
                      onClick={!isLastStep ? handleNext : formik.submitForm}
                    >
                      <>
                        {isLastStep
                          ? translate("roles.add")
                          : translate("roles.next")}
                      </>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AddUser;
