import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Icon, IconButton } from "@mui/material";

import MDTypography from "../MDTypography";
import MDBadge from "../MDBadge";
import MDBox from "../MDBox";
import pxToRem from "assets/theme/functions/pxToRem";

interface Props {
  onChange?: (files: File[]) => void;
  reset?: boolean;
}

function MDDropzone({ onChange, reset }: Props): JSX.Element {
  const [files, setFiles] = useState<File[]>([]);

  const onRemove = (file: File) => {
    setFiles(files.filter((item) => item != file));
  };

  useEffect(() => {
    if (reset) {
      setFiles([]);
    }
  }, [reset]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  useEffect(() => {
    if (reset) {
      // Reset the dropzone on submit
      setFiles([]);
    }
  }, [reset]);

  return (
    <Dropzone onDrop={(acceptedFiles) => setFiles(files.concat(acceptedFiles))}>
      {({ getRootProps, getInputProps }) => (
        <section style={{ paddingBottom: pxToRem(8) }}>
          <MDBox
            {...getRootProps()}
            sx={{ border: "1px solid gray", borderRadius: 0.5 }}
            p={2}
          >
            <input {...getInputProps()} />
            {files.length ? (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {files.map((file) => (
                  <MDBox
                    key={file.name}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <>
                      <MDBadge
                        badgeContent={file.name.substring(
                          file.name.lastIndexOf(".") + 1
                        )}
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                        <MDTypography fontSize={14}>{file.name}</MDTypography>
                        <MDTypography fontSize="small" sx={{ opacity: "0.5" }}>
                          {Math.trunc(file.size / 1024)}kb
                        </MDTypography>
                      </MDBox>

                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove(file);
                        }}
                        sx={{
                          display: "flex",
                          itemsAlign: "flex-start",
                          opacity: "0.5",
                        }}
                      >
                        <Icon fontSize="small">close</Icon>
                      </IconButton>
                    </>
                  </MDBox>
                ))}
              </MDBox>
            ) : (
              <MDTypography variant="body2">
                Drag 'n' drop some files here, or click to select files
                <MDTypography variant="body2" style={{ fontSize: 10 }}>
                  Accepted file types:
                  JPEG/PNG/MP3/MP4/DOCX/XLSX/PPTX/JPG/PNGX/TXT/DOC/CSV/XLS/PPT/EML/MSG
                </MDTypography>
              </MDTypography>
            )}
          </MDBox>
        </section>
      )}
    </Dropzone>
  );
}

export default MDDropzone;
