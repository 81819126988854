import AuthLayout from "../../layouts/AuthLayout";
import MDBox from "../../components/mui/MDBox";
import MDInput from "../../components/mui/MDInput";
import React, { useState } from "react";
import { translate } from "../../translations/i18n";
import MDButton from "../../components/mui/MDButton";
import { Endpoints, postRequest } from "../../helpers/api";
import { toast } from "react-toastify";

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");

  const requestResetPassword = async () => {
    await postRequest(Endpoints.RESET_PASSWORD_REQUEST, {
      email: email,
    })
      .then(() => {
        toast.success(translate("toastrMessages.resetPasswordRequestSucces"));
        setEmail("");
      })
      .catch((error) => {
        console.log(error);
        setEmail("");
      });
  };

  return (
    <AuthLayout
      title={translate("requestPasswordReset.title")}
      description={translate("requestPasswordReset.description")}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            value={email || ""}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setEmail(e.target.value);
            }}
            label={translate("login.emailLabel")}
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            onClick={requestResetPassword}
          >
            {translate("requestPasswordReset.buttonLabel")}
          </MDButton>
        </MDBox>
      </MDBox>
    </AuthLayout>
  );
};

export default RequestPasswordReset;
