import { useCallback, useEffect, useState } from "react";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { Endpoints, getRequest } from "helpers/api";
import { translate } from "translations/i18n";
import {
  EPetitionType,
  petitionFilters,
  petitionTableColumns,
} from "types/petitionTypes";
import DataTable from "components/Tables/DataTable";
import { formatDateWithLocale } from "utils/utilsFunctions";
import { displayPetitionLabel, renderActions } from "helpers/petitionsHelpers";
import { FiltersData } from "types/dataTableTypes";
import pxToRem from "assets/theme/functions/pxToRem";
import { IAgent } from "../../../interfaces/petitions";

const defaultSearchParams = {
  sortDir: "ASC",
  page: 1,
  perPage: 10,
  search: "",
};

interface Filters {
  [key: string]: string | null | boolean;
}

interface Agent {
  [key: string]: string;
}

const PetitionsList = () => {
  const navigate = useNavigate();
  const [petitions, setPetitions] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState<Filters>({});
  const [agents, setAgents] = useState<Agent>({});

  useEffect(() => {
    getRequest(`${Endpoints.PETITIONS.LIST}`, filters)
      .then((petitions) => {
        setPetitions(petitions.data);
        setTotalItems(petitions.totalItems);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filters]);

  useEffect(() => {
    const params = {
      ...defaultSearchParams,
    };

    getRequest(`${Endpoints.PETITIONS.AGENTS}`, params)
      .then((res) => {
        setAgents(
          res?.agents.reduce(
            (acc: { [key: string]: string }, agent: IAgent) => ({
              ...acc,
              [agent.id]: agent.name,
            }),
            {}
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const generateFilters = () => {
    return petitionFilters.map((filter) => {
      if (filter.name === "petitionAgentId") {
        return {
          ...filter,
          valueOptions: {
            null: translate("dataTable.unassignedLabel"),
            ...agents,
          },
        };
      } else if (filter.name === "petitionType") {
        return {
          ...filter,
          valueOptions: {
            [EPetitionType.UNIQUE]: displayPetitionLabel(EPetitionType.UNIQUE),
            [EPetitionType.DUPLICATE]: displayPetitionLabel(
              EPetitionType.DUPLICATE
            ),
          },
        };
      } else {
        return filter;
      }
    });
  };

  const handleFiltersChange = useCallback(
    (newFilters: FiltersData) => {
      const isPetitionAgentIdNull = newFilters.petitionAgentId === "null";
      const updatedFilters: FiltersData = { ...newFilters };
      if (isPetitionAgentIdNull) {
        delete updatedFilters.petitionAgentId;
        updatedFilters.isAssigned = false;
      }

      setFilters(updatedFilters);
    },
    [setFilters]
  );

  const rows = petitions.map((petition) => ({
    ...petition,
    policyNumber: petition.policyNumber || petition.websitePolicyId,
    petitionAgentId: petition.petitionAgentId ? (
      agents[petition.petitionAgentId]
    ) : (
      <MDTypography
        variant="overline"
        fontWeight="bold"
        backgroundColor="unassignedLabelBg"
        padding={`${pxToRem(4)} ${pxToRem(8)}`}
        letterSpacing={0}
        borderRadius={pxToRem(4)}
        sx={{ color: "unassignedLabelText" }}
      >
        {translate("dataTable.unassignedLabel")}
      </MDTypography>
    ),
    petitionType: displayPetitionLabel(petition.petitionType),
    receptionDate: formatDateWithLocale(petition.receptionDate),
    actions: renderActions(petition, navigate),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5" fontWeight="medium">
                {translate("petitions.title")}
              </MDTypography>
            </MDBox>
            <DataTable
              canSearch
              table={{
                columns: petitionTableColumns,
                rows: rows,
              }}
              filters={generateFilters()}
              totalItems={totalItems}
              onFiltersChange={handleFiltersChange}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default PetitionsList;
