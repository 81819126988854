import Card from "@mui/material/Card";
import { AddCircle, Close, Done } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/mui/MDTypography";
import MDBox from "components/mui/MDBox";
import { renderActions, rolesTableColumns } from "helpers";
import { translate } from "translations/i18n";
import { Endpoints, getRequest } from "helpers/api";
import { IRole } from "interfaces";
import { formatDateWithLocale } from "utils/utilsFunctions";

const RolesList = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<IRole[]>([]);

  useEffect(() => {
    getRequest(Endpoints.ROLES)
      .then((res) => {
        setRoles(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {translate("roles.title")}
              </MDTypography>
              <IconButton
                color="success"
                onClick={() => navigate("/roles/addrole")}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            </MDBox>
            <DataTable
              hasSorting={false}
              table={{
                columns: rolesTableColumns,
                rows: roles.map((role) => ({
                  ...role,
                  createdAt: formatDateWithLocale(role.createdAt),
                  isActive: role.active ? (
                    <Done fontSize="medium" />
                  ) : (
                    <Close fontSize="medium" />
                  ),
                  actions: renderActions(role, navigate),
                })),
              }}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default RolesList;
