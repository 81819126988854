import * as yup from "yup";

import { translate } from "translations/i18n";
import {
  EPetitionResolutionEndMode,
  EPetitionResolveStatus,
  EPetitionSolicitant,
  EPetitionType,
} from "types/petitionTypes";
import { CNP_REGEX, EMAIL_REGEX, PHONE_NUMBER_REGEX, validateCNP } from "utils";

export const newPetitionValidationSchema = yup.object({
  petitionerName: yup
    .string()
    .required(translate("newPetition.validations.requiredPetitionerName"))
    .typeError(translate("newPetition.validations.stringField")),
  petitionerPhoneNumber: yup
    .string()
    .matches(
      PHONE_NUMBER_REGEX,
      translate("newPetition.validations.invalidPhoneNumber")
    )
    .required(translate("newPetition.validations.requiredPhoneNumber"))
    .typeError(translate("newPetition.validations.stringField")),
  petitionerEmail: yup
    .string()
    .matches(
      EMAIL_REGEX,
      translate("newPetition.validations.invalidEmailAddress")
    )
    .typeError(translate("newPetition.validations.stringField")),
  solicitant: yup
    .string()
    .required(translate("newPetition.validations.requiredSolicitant"))
    .typeError(translate("newPetition.validations.stringField")),
  petitionerType: yup
    .string()
    .required(translate("newPetition.validations.requiredPetitionerType"))
    .typeError(translate("newPetition.validations.stringField")),
  petitionObject: yup
    .string()
    .required(translate("newPetition.validations.requiredPetitionObject"))
    .typeError(translate("newPetition.validations.stringField")),
  insuranceClass: yup
    .string()
    .required(translate("newPetition.validations.requiredInsuranceClass"))
    .typeError(translate("newPetition.validations.stringField")),
  inputChannel: yup
    .string()
    .required(translate("newPetition.validations.requiredSource"))
    .typeError(translate("newPetition.validations.stringField")),
  urgency: yup
    .string()
    .required(translate("newPetition.validations.requiredUrgency"))
    .typeError(translate("newPetition.validations.stringField")),
  petitionId: yup
    .number()
    .typeError(translate("newPetition.validations.numberField")),
  petitionerIdentificationNumber: yup
    .string()
    .matches(
      CNP_REGEX,
      translate("newPetition.validations.petitionerIdentificationNumber")
    )
    .test(
      "valid-cnp",
      translate("newPetition.validations.invalidIdentificationNumber"),
      (value) => validateCNP(value)
    ),
  petitionerAddress: yup
    .string()
    .typeError(translate("newPetition.validations.stringField")),
  petitionContent: yup
    .string()
    .required(translate("newPetition.validations.petitionContent"))
    .typeError(translate("newPetition.validations.stringField")),
  policyNumber: yup
    .number()
    .typeError(translate("newPetition.validations.numberField")),
  claimNumber: yup
    .string()
    .typeError(translate("newPetition.validations.stringField")),
  petitionAgentId: yup
    .string()
    .typeError(translate("newPetition.validations.stringField")),
  receptionDate: yup
    .date()
    .required(translate("newPetition.validations.requiredReceptionDate"))
    .typeError(translate("newPetition.validations.dateField")),
  asfRegistrationDate: yup
    .date()
    .when("solicitant", (solicitant, schema) => {
      return solicitant === EPetitionSolicitant.THIRD_PARTY
        ? schema.required(
            translate("newPetition.validations.requiredAsfRegistrationDate")
          )
        : schema;
    })
    .typeError(translate("newPetition.validations.dateField")),
  asfNumber: yup
    .number()
    .when("solicitant", (solicitant, schema) => {
      return solicitant === EPetitionSolicitant.THIRD_PARTY
        ? schema.required(
            translate("newPetition.validations.requiredAsfNumber")
          )
        : schema;
    })
    .typeError(translate("newPetition.validations.numberField")),
  internalDeadline: yup
    .string()
    .required(translate("newPetition.validations.requiredInternalDeadline"))
    .typeError(translate("newPetition.validations.stringField")),
});

export const updatePetitionValidationSchema = yup.object({
  updateNotes: yup
    .string()
    .typeError(translate("newPetition.validations.dateField")),
  petitionResolveStatus: yup
    .string()
    .required(
      translate(
        "petitionDetails.petitionUpdates.validations.requiredPetitionResolveStatus"
      )
    )
    .typeError(translate("newPetition.validations.stringField")),
  originalPetitionId: yup
    .string()
    .nullable()
    .when("petitionType", (petitionType, schema) => {
      return petitionType === EPetitionType.DUPLICATE
        ? schema.required(
            translate(
              "petitionDetails.petitionUpdates.validations.requiredOriginalPetitionId"
            )
          )
        : schema;
    })
    .typeError(translate("newPetition.validations.stringField")),

  petitionAgentId: yup
    .number()
    .required(
      translate(
        "petitionDetails.petitionUpdates.validations.requiredPetitionAgentId"
      )
    )
    .typeError(translate("newPetition.validations.numberField")),
  resolutionDate: yup
    .string()
    .when("petitionResolveStatus", {
      is: (val: string | undefined) =>
        val === EPetitionResolveStatus.FINALIZATA,
      then: yup
        .string()
        .required(
          translate(
            "petitionDetails.petitionUpdates.validations.requiredResolutionDate"
          )
        ),
      otherwise: yup.string(),
    })
    .typeError(translate("newPetition.validations.stringField")),
  petitionResolutionEndMode: yup
    .string()
    .when("petitionResolveStatus", {
      is: (val: string | undefined) =>
        val === EPetitionResolveStatus.FINALIZATA,
      then: yup
        .string()
        .required(
          translate(
            "petitionDetails.petitionUpdates.validations.requiredPetitionResolutionEndMode"
          )
        )
        .nullable(),
      otherwise: yup.string().nullable(),
    })
    .typeError(translate("newPetition.validations.stringField")),
  petitionResolutionEndReason: yup
    .string()
    .when("petitionResolveStatus", {
      is: (val: string | undefined) =>
        val === EPetitionResolveStatus.FINALIZATA,
      then: yup.string().when("petitionResolutionEndMode", {
        is: (val: string | undefined) =>
          val === EPetitionResolutionEndMode.NEINTEMEIAT,
        then: yup
          .string()
          .required(
            translate(
              "petitionDetails.petitionUpdates.validations.requiredPetitionResolutionEndReason"
            )
          )
          .nullable(),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    })
    .typeError(translate("newPetition.validations.stringField")),
});
